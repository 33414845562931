export function getIntercomUser( user, basicUserData ) {
  const intercomUser = {};
  //
  // both /login and /user use email
  //
  // user.username is equal to user.email in /login
  if (!user || ( !user.email && !user.username ) ) {
    console.warn( "intercom failed to load, empty user" );
    return null;
  }

  const externalSources = user.properties ? user.properties.externalSources : user.externalSources;

  if ( externalSources && externalSources.length > 0 ) {
    this && this.shutdown && this.shutdown();
    // console.info("getIntercomUser - Disable intercom.io for sua user ", user);
    return null;
  }

  intercomUser.user_id = user.id;
  intercomUser.user_name = user.email;
  intercomUser.email = user.email;
  intercomUser.name = `${ user.firstName } ${ user.lastName }`;
  intercomUser.created_at = (basicUserData || user).createdAt;
  intercomUser.role = user.role;
  intercomUser.identity = user.identity;
  intercomUser.currency = user.currency || "USD";
  intercomUser.companyName = user.companyName;

  return intercomUser;
}
