import ExtendedAxios from '../helpers/axios';

import {FFM_CAMPAIGN_API_URL, FFM_CONSOLE_API_URL} from '../config/env';

const axios = new ExtendedAxios({baseURL: FFM_CAMPAIGN_API_URL});

export default class InvitationService {
  static setCookie ( affiliate ) {
    return axios.get( `/connection/${encodeURIComponent(affiliate)}/create` );
  }
}
