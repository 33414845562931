import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { ScaleLoader } from 'react-spinners';
import { injectIntl } from 'react-intl'

import {
  LoginTitle,
  RoleContainer,
  LoadingContainer,
  ConvertRoleDescription, ConvertButton, WarningIcon, Separator, SingupLink, Error, SuccessIcon
} from "../../Login/styled";
import {
  FFM_CONSOLE_API_URL,
} from "@featurefm/common/config/env";
import { addProtocolToUrl } from "@featurefm/common/helpers/url";
import { sendInvitation } from "../../../redux/sendInvitation/actions";
import Logo from "@featurefm/common/components/Logo";

class AcceptedUserDelegationInvitation extends Component {
  state = {
    loading: false,
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { intl, sent, error, jwt, dest } = this.props;
    const { loading } = this.state;

    const retry = e => {
      this.setState({ loading: true });

      setTimeout(() => {
        this.props.sendInvitation(jwt);
      })
    };

    let sendButtonCaption = intl.messages.RESEND_INVITE;
    if (error) {
      sendButtonCaption = intl.messages.RETRY_OPERATION;
    } else if (sent) {
      sendButtonCaption = intl.messages.RESEND_INVITE;
    }

    return (<>
        <RoleContainer>
          <Logo style={{ marginBottom: 34 }}/>

          {!!error && (
            <WarningIcon>
              <FontAwesomeIcon icon={ faExclamationTriangle } />
            </WarningIcon>
          )}

          {!!sent && (
            <SuccessIcon>
              <FontAwesomeIcon icon={ faCheck } />
            </SuccessIcon>
          )}

          <LoginTitle style={{ margin: '2em 0px'}}>
            { intl.messages.SEND_INVITATION_MODAL_TITLE }
          </LoginTitle>
          <ConvertRoleDescription>
            { !!sent && intl.messages.SEND_INVITATION_MODAL_SUBTITLE_SUCCESS }
            { !!error && <Error id="auth-error">{intl.messages.SEND_INVITATION_MODAL_SUBTITLE_ERROR}</Error>}
          </ConvertRoleDescription>

          <ConvertButton id="button-retry" variant="accent" loading={ loading } onClick={retry}>
            { !loading && sendButtonCaption }
            { loading && <LoadingContainer id="button-retry-loading"><ScaleLoader color={'#fff'} /></LoadingContainer> }
          </ConvertButton>

          <Separator style={{marginTop: 15}} text="OR" />

          <SingupLink id="link-continue-to-site">
            <a href={addProtocolToUrl(`${FFM_CONSOLE_API_URL}/issuer-redirect-ffm?redirectTo=${encodeURIComponent(dest)}&jwt=${encodeURIComponent(jwt)}`)}>{ intl.messages.CONTINUE_TO_SITE_IF_INVITATION_ACCEPTED } →</a>
          </SingupLink>

        </RoleContainer>
      </>
    );
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
});

const mapDispatchToProps = {
  sendInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)( injectIntl(AcceptedUserDelegationInvitation) );
