// import zxcvbn from 'zxcvbn';
import isURI from 'validate.io-uri';
import {addProtocolToUrl} from "../helpers/url";

/**
 * temporary thing since we'll be using intl
 */
export const ERROR_MESSAGES = {
  required: 'This field is required',
  name: 'Must be at least 2 characters long',
  accountType: 'Please choose your account type',
  email: 'Must be a valid email',
  password: 'Must be at least 8 characters long and contain 1 lowercase, 1 uppercase and 1 numeral character',
  artist: 'Must be a valid Spotify or Deezer Artist URL',
  website: 'Please enter a valid website URL',
  instagramUrl: 'Please enter a valid Instagram URL',
  facebookUrl: 'Please enter a valid Facebook URL',
  soundcloudUrl:  'Please enter a valid Soundcloud URL',
  twitterHandle: 'Please enter a valid Twitter handle or an account URL',
  youtubeChannel:  'Please enter a valid YouTube channel URL',
  spotifyArtistUrl:  'Please enter a valid Spotify artist page URL',
  soundcloudArtistUrl: 'Please enter a valid SoundCloud artist page URL',
  tiktokArtistUrl: 'Please enter a valid TikTok profile URL',
  youtubeVideoUrl:  'Please enter a valid YouTube video URL'
}

const nameValidator = value => {
  return value.length >= 2 || ERROR_MESSAGES.name;
}

const email = value => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test( value ) || ERROR_MESSAGES.email;
}

//  Mb we'll use this later
const passwordZxcvbn = value => {
  const result = zxcvbn( value );
  return result.score > 2 || result.feedback.suggestions
}

const password = value => {
  //  1 lowercase
  //  1 uppercase
  //  1 numeral
  //  at lease 8 characters
  const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

  return regex.test( value ) || ERROR_MESSAGES.password;
}

export const url = (value, requireProtocol = false ) => {
  if (!value) return true;

  // const gruberv2 = /^\b((?:https?:\/\/|www\d{0,3}[.]|[a-z0-9.\-]+[.][a-z]{2,4}\/)(?:[^\s()<>]+|\(([^\s()<>]+|(\([^\s()<>]+\)))*\))+(?:\(([^\s()<>]+|(\([^\s()<>]+\)))*\)|[^\s`!()\[\]{};:'".,<>?«»“”‘’]))$/i;

  // taken from https://gist.github.com/dperini/729294, via https://mathiasbynens.be/demo/url-regex
  let re_weburl = new RegExp(
      "^" +
          // protocol identifier
      "(?:(?:https?|ftp|):?//)" + (requireProtocol ? '' : '?') +
          // user:pass authentication
      "(?:\\S+(?::\\S*)?@)?" +
      "(?:" +
          // IP address exclusion
          // private & local networks
      "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
      "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
      "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
          // IP address dotted notation octets
          // excludes loopback network 0.0.0.0
          // excludes reserved space >= 224.0.0.0
          // excludes network & broacast addresses
          // (first & last IP address of each class)
      "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
      "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
      "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
      "|" +
          // host name
      "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)" +
          // domain name
      "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*" +
          // TLD identifier
      "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))" +
          // TLD may end with dot
      "\\.?" +
      ")" +
          // port number
      "(?::\\d{2,5})?" +
          // resource path
      "(?:[/?#]\\S*)?" +
      "$", "i"
  );

  return re_weburl.test(value);
}

const spotifyGenericLinkUrl = value => {
  if (!value) return true;

  if (!url(value)) return false;

  const p = /^$|^(?:(?:[hH][tT][tT][pP][sS]?:\/\/)?(?:[sS][pP][oO][tT][iI][fF][yY]\.[lL][iI][nN][kK]\/))(\w+)/;
  return (value.match(p) ? RegExp.$1 : false); // return the item id after validation
}

const spotifyArtistURL = value => {
  if (!value) return true;

  if (!url(value)) return false;

  if (spotifyGenericLinkUrl(value)) return true;

  var p = /^$|^(?:(?:[hH][tT][tT][pP][sS]?:\/\/)?(?:[oO][pP][eE][nN]\.)?(?:[sS][pP][oO][tT][iI][fF][yY]\.[cC][oO][mM](?:\/intl-[A-Za-z]{2})?\/artist\/)|(?:[sS][pP][oO][tT][iI][fF][yY]:[aA][rR][tT][iI][sS][tT]:))(\w+)/;
  return (value.match(p) ? RegExp.$1 : false); // return the track id after validation
}

const spotifyArtistURI = value => {
    if (!value) return true;
    if (!isURI(value)) return false;
    if (value.match(/^spotify:artist:[0-9,a-z,A-Z]+$/)) return true;
    return false;
}

const deezerArtistURL = value => {
  if (!value) return true;

  if (!url(value)) return false;

  var p = /^$|^(?:[hH][tT][tT][pP][sS]?:\/\/)?(?:[wW][wW][wW]\.)?(?:[dD][eE][eE][zZ][eE][rR]\.[cC][oO][mM]\/?.*\/artist\/)(\d+)$/;
  return (value.match(p) ? RegExp.$1 : false); // return the track id after validation
}

const tidalArtistURL = value => {
  if (!value) return true;

  if (!url(value)) return false;

  const p = /^$|^(?:[hH][tT][tT][pP][sS]?:\/\/)?(?:[\w].+)?tidal.*\..*\/(?:artist)\/([\w-]+)(?:.+)?$/;
  return (value.match(p) ? RegExp.$1 : false); // return the track id after validation
}
/**
 * If it matches general URI scheme,
 * then it should be a valid spotify or deezer URL.
 *
 * Otherwise, just 3 characters rule as with the name
 */
const artistLink = value => {
  const wellFormattedValue = url(value) ? addProtocolToUrl(value) : value;

  const uri = isURI( wellFormattedValue );
  const validURL = deezerArtistURL( wellFormattedValue ) || spotifyArtistURL( wellFormattedValue ) || spotifyArtistURI( wellFormattedValue );

  return ( uri && !!validURL )
}

const validateFacebookURL = value => {
  if (!value) return true;

  if (!url(value)) return false;

  return value.match(/^$|(?:[hH][tT][tT][pP][sS]?:\/\/)?(?:[wW][wW][wW]\.|[mM]\.|[\w-]+\.)?[fF][aA][cC][eE][bB][oO][oO][kK].[cC][oO][mM]\/(?:pages\/|people\/)?([\w-\.%]+)(?:\/[0-9]+)?(?:\?.*)?/);
}

const validateInstagramURL = value => {
  if (!value) return true;
  if (!url(value)) return false;

  return value.match(/^https?:\/\/(www\.)?instagram\.com\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)(?:\/)?(?:\?.*)?$/i);
}

const validateTwitterHandler = value => {
  if (!value) return true;

  return value.match(/^$|^@([\w]+)$|^(?:[hH][tT][tT][pP][sS]?:\/\/)?(?:[wW][wW][wW]\.|mobile\.)?(?:twitter|x)\.com\/(?:#!\/)?@?([\w]+)(?:\/|\?.*)?$/);
}

/**
* Validate YouTube channel URL
*
* @param {String} value - presumed YouTube channel URL
* @returns {Boolean}
*/
const validateYouTubeChannelURL = (value) => {
  if (!value) return true;

  if (!url(value)) return false;

  const regExResult = (`${value}`).match(/(https?:\/\/)?(www\.)?youtu((\.be)|(be\..{2,5}))\/(?:(?:(user|channel|c)\/)|(?:(@))?)([\w\-_]+)(\/.*)?/);

  return (
      regExResult
      && (
          regExResult[6] === 'user'
          || regExResult[6] === 'channel'
          || regExResult[6] === 'c'
          || regExResult[7] === '@'
          || (!regExResult[6] && !regExResult[7] && (
              !regExResult[9]
              || regExResult[9].startsWith('?')
              || regExResult[9].startsWith('#')
              || regExResult[9].startsWith('/?')
              || regExResult[9] === '/'
          )) // will match user channel URLs like https://www.youtube.com/awolnation, but not unknown urls like https://www.youtube.com/asd/awolnation
      )
      && (regExResult[8] && regExResult[8].length > 1)
  );
}

/**
* Validate Soundcloud page URL
*
* @param {String} value - presumed Soundcloud page URL
* @returns {Boolean}
*/
const validateSoundcloudURL = value => {
  if (!value) return true;
  if (!url(value)) return false;

  var regexp = /^(?:[hH][tT][tT][pP][sS]?:\/\/)?(?:[wW][wW][wW]\.|[mM]\.)?(?:soundcloud.com|snd.sc)\/(.*)$/;
  return (value.match(regexp) ? RegExp.$1 : false); // return the soundcloud id after validation
}
/**
* Validate TikTok profile link via regex
*
* Yurii:
*  I don't feel like we can reliably diferentiate between
*  profile links and video links if somebody uses a short link
*  shared from mobile app.
*
*  For We need to come up with some sort of compromise here 🤔
*
*  I'll also apply similar comments to functions as I go.
*
*
* @param {String} value - presumed TikTok URL
* @returns {String|Boolean}
*/
const validateTiktokURL = value => {
  if (!value) return true;
  if (!url(value)) return false;

  //  This is the general regex
  //
  //  In order to match Profile URL or Video URL we'll need to fetch HEAD
  //  request and detect any king of 3XX redirect to get the real URL
  //  instead of a short one
  const regexp = /^(http:\/\/|https:\/\/)?((vm|www)?(\.)?tiktok\.com\/)(.+$)/i;
  const match = value.match(regexp);

  //  Short link:
  if ( match.length > 0 && match[3] === "vm" ) {
      //  Do something with short link
      //
      //  ID of short link
      // console.info( "Matched short TikTok link:", value );
      return match[5];
  //  Long link:
  } else if ( match.length > 0 ) {
      //  Do something with a long link.
      //
      //  e.g.
      //      return @username from /@username link
      //      return videoID from /@username/video/{videoID} link
      // console.info( "Matched long TikTok link:", {
      //     value,
      //     username: match[5].split("/")[0],
      //     video: match[5].includes("video"),
      //     videoID: match[5].includes("video") ? match[5].split("/")[2] : null
      // } );

      return match[5];
  }

  return ERROR_MESSAGES.tiktok;
}

export default {
  name: nameValidator,
  firstName: nameValidator,
  lastName: nameValidator,
  companyName: nameValidator,
  email,
  passwordZxcvbn,
  password,
  url,
  spotifyArtistURL,
  spotifyArtistURI,
  deezerArtistURL,
  tidalArtistURL,
  artistLink,

  website: value => {
    return !!url( value ) || ERROR_MESSAGES.website
  },
  facebook: value => {
    return !!validateFacebookURL( value ) || ERROR_MESSAGES.facebookUrl
  },
  instagram: value => {
    return !!validateInstagramURL( value ) || ERROR_MESSAGES.instagramUrl
  },
  soundcloud: value => {
    return !!validateSoundcloudURL( value ) || ERROR_MESSAGES.soundcloudArtistUrl
  },
  twitter: value => {
    return !!validateTwitterHandler( value ) || ERROR_MESSAGES.twitterHandle
  },
  youtube: value => {
    return !!validateYouTubeChannelURL( value ) || ERROR_MESSAGES.youtubeChannel
  },
  spotifyArtistPage: value => {
    return !!spotifyArtistURL( value ) || !!spotifyArtistURI( value ) || ERROR_MESSAGES.spotifyArtistUrl
  },
  tiktokArtistPage: value => {
    return !!validateTiktokURL( value ) || ERROR_MESSAGES.tiktokArtistUrl
  }
}
