import { useEffect, useState, } from "react";
import { useLocation } from "react-router-dom";
import analytics from "../helpers/analytics";

const usePageTracking = () => {
    const location = useLocation();
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        analytics.initialize().then(() => {
            setInitialized(true)
        });
    }, []);

    useEffect(() => {
        if (initialized && location) {
            analytics.pageview(location.pathname + location.search);
        }
    }, [initialized, location]);
};

export default usePageTracking;
