import styled from 'styled-components';
import Color from 'color';

import { flex, flexCol, breakpointMax } from '../styled/mixins';

import LogoLight from '../../assets/logo/logo-header-lightbg.svg';
import LogoDark from '../../assets/logo/logo-header-darkbg.svg';
import AdaLogoDark from '../../assets/logo/logo-header-ada-darkbg.svg'
import { COLORS } from '../../config';

export const Wrapper = styled.div`
  ${ flex }
  ${ flexCol }

  align-items: center;
`;

export const Logo = styled.div`
  width: 160px;
  height: 48px;

  background: url(${ props => 
    props.theme.logoStyle === 'light' ? LogoLight : 
        props.theme.name === 'ada' ? AdaLogoDark : LogoDark });
  background-size: 100%;
  background-repeat: no-repeat;

  ${ breakpointMax( 'md', `
    width: 186px;
    height: 60px;
  ` )}
`;

export const Text = styled.div`
  margin-top: 14px;

  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  
  color: ${ props => Color( props.theme.textMain ).alpha(0.5).rgb().string() };
  display: ${ props => props.theme.name === 'ada' ? 'none' : 'block'};

  ${ breakpointMax( 'md', `
    font-size: 12px;
    line-height: 15px;
  ` ) }
`;
