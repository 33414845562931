import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Redirect } from "react-router-dom";
import usePageTracking from "@featurefm/common/hooks/usePageTracking";
import { handleRedirectToFfm } from '../../redux/app/actions';

import {
  FFM_CONSOLE_NEXT_WEB_URL,
  FFM_CONSOLE_NEXT_REDIRECT_PATH,
  FFM_CONSOLE_WEB_URL
} from "@featurefm/common/config/env";
import { AcceptInviteLoading } from "../../containers";

export function RestrictedRoute( props ) {
  const {
    component: Component,
    children,
    isAuthenticated,
    appLoading,
    authLoading,
    ...rest
  } = props;

  usePageTracking();
  const render = props => {
    if ( authLoading || isAuthenticated !== false ) {
      return children ? children : <Component {...props} />
    } else {
      return <Redirect
        to={{
          pathname: "/login",
          state: { from: props.location },
        }}
      />
    }
  };

  return <Route {...rest} render={ render } />;
}

export function ReverseRestrictedRoute(props) {
  const {
    component: Component,
    children,
    isAuthenticated,
    appLoading,
    authLoading,
    invitationHash,
    ...rest
  } = props;

  usePageTracking();

  const { dest } = useSelector(state => state.App);
  const dispatch = useDispatch();

  const render = props => {
    if ( !authLoading && isAuthenticated !== false ) {

      if (invitationHash) {
        return <AcceptInviteLoading {...props} />
      }
      else {
        //  Redirect to dest if specified in qs, otherwise to the main app
        dispatch(handleRedirectToFfm(dest || (FFM_CONSOLE_NEXT_WEB_URL + FFM_CONSOLE_NEXT_REDIRECT_PATH)));
      }
      return null;
    } else {
      return <Component {...props} />
    }
  }

  return <Route {...rest} render={ render } />;
}

export function NonRestrictedRoute( props ) {
  const {
    component: Component,
    children,
    isAuthenticated,
    appLoading,
    authLoading,
    ...rest
  } = props;

  usePageTracking();
  const render = props => {
    return children ? children : <Component {...props} />
  };

  return <Route {...rest} render={ render } />;
}
