import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { MetroSpinner } from "react-spinners-kit";
import { withRouter } from 'react-router-dom';
import analytics from "@featurefm/common/helpers/analytics";
import * as GTM from "../../../utils/gtm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  FFM_CONSOLE_NEXT_WEB_URL,
  FFM_CONSOLE_NEXT_REDIRECT_PATH,
} from "@featurefm/common/config/env";
import {
  validateField,
  submitReady,
  formChange,
} from "@featurefm/common/helpers/form";
import MiscService from "@featurefm/common/services/misc";

import { logout } from "../../../redux/auth/actions";

import { HeaderContainer } from "../../../components/common/Header/styled";
import { Container } from "../../../components/Main.styled";

import QuickForm from "../components/QuickFormArtist";
import ManualForm from "../components/ManualFormArtist";

import { Main, HeaderButton } from "../styled";
import { LoadingContainer } from "../../Login/styled";
import { ARTIST_FIELDS, getClientCountryCurrency, getCurrencyFromQueryString } from "@featurefm/common/helpers/onboarding";
import { ERROR_MESSAGES, url as isUrl } from "@featurefm/common/validators";
import mixpanel from "../../../utils/mixpanel";
import {getArtistSocialIcons, getUserAccountName} from "@featurefm/common/helpers/mixpanel";
import {handleRedirectToFfm} from "../../../redux/app/actions";

const miscService = new MiscService();

class Onboarding extends Component {
  state = {
    form: null,
    manual: false,
    submitLoading: false,
    error: null,
    selectedArtist: null,
    listedArtists: null,
    review: false,
    locationName: '',
  };

  selectArtist = (artist) => {
    const form = this.state.form
    ? update(this.state.form, { $merge: { ...artist } })
    : artist;

    this.setState({ form, selectedArtist: artist });

    // If currency exists in query-string, we directly submit instead of reviewing
    if (getCurrencyFromQueryString(this.props.location.search)) {
      setTimeout(() => this.submit())
    }
    else {
      this.reviewProfile()
    }
  }

  setListedArtists = (artists) => {
    this.setState({
      listedArtists: [
        ...(artists || []),
      ]
    });
  };

  clearListedArtists = () => {
    this.setState({
      listedArtists: null
    });
  };

  toggleManual = (e) => {
    const { user } = this.props;
    const { form } = this.state;

    const manual = !this.state.manual;

    const newState = {
      manual,
      review: manual
    };

    if (!form.name && user != null) {
      const name = user.fullName
        ? user.fullName
        : `${user.firstName} ${user.lastName}`;

      newState.form = update(form, { $merge: { name } });
    }

    this.setState(newState);
  };

  presetLocation = async () => {
    const { location, currency } = await getClientCountryCurrency();
    const qsCurrency = getCurrencyFromQueryString(this.props.location.search);

    this.locationChange({ value: location });
    this.currencyChange({ value: qsCurrency || currency });
  };

  locationChange = (e) => {
    this.setState({
      form: update(this.state.form, { $merge: { location: e.value } }),
      locationName: (e && e.label && e.label.props && e.label.props.location && e.label.props.location.text) || '',
    });
  };

  currencyChange = (e) => {
    this.setState({
      form: update(this.state.form, { $merge: { currency: e.value } }),
    });
  };

  setPicture = (profileImage) => {
    this.setState({
      form: update(this.state.form, { $merge: { profileImage } }),
    });
  };

  clearPicture = () => {
    this.setState({
      form: update(this.state.form, { $merge: { profileImage: "" } }),
    });
  };

  reviewProfile = (e) => {
    this.setState({ review: true, manual: true })
  }

  submit = async (e) => {
    const { user } = this.props;

    if ( this.validateRequired() ) {
      const form = { info: this.state.form };
      const completesignup = _.pick( this.state.form, ["currency", "location"] );

      this.setState({ submitLoading: true });

      GTM.track("Signup", "Save Clicked");

      try {
        const editArtistRes = await miscService.editArtist(form);

        //  This is kinda bad, but services don't throw errors, they return.
        if ( editArtistRes.error ) {
          throw editArtistRes.error;
        }

        const completesignupRes = await miscService.completesignup(completesignup);

        //  This is kinda bad, but services don't throw errors, they return.
        if ( completesignupRes.error ) {
          throw completesignupRes.error;
        }

        if (user) {
          mixpanel.identify(user.id);

          mixpanel.registerSuperProperties({
            '$email': user.email,
            '$name': getUserAccountName(user),
            'Plan Name': user.plan,
            'Account Name': getUserAccountName(user),
            'Artist Name': editArtistRes.data.info.name,
          });

          // This is also called in mixpanel boot function on console-next. seems to be redundant here
          // void mixpanel.refreshUserProfileProperties();

          mixpanel.track('Profile Created', {
            'Artist Name': editArtistRes.data.info.name,
            'Date Created': new Date().toISOString(),
            'Social Icons': getArtistSocialIcons(editArtistRes.data.info),
            'Location': this.state.locationName || editArtistRes.data.info.location,
            'Has Profile Image': !!editArtistRes.data.info.profileImage,
            'Page Name': 'Artist Signup - Onboarding',
            'Page Element': 'Save Button',
          });
        }

        await analytics.trackEvent(
            "Artist Sign Up",
            "Completed",
            analytics.getPlanLabel(),
        )

        if (this.props.invitationHash) {
          // onboarding has finished successfully,
          // so refreshing the login URL will process the accept-invitation flow
          // we do **not** go through handleRedirectToFfm saga here, as we don't want to impersonate yet,
          // only refresh the page while retaining the query params
          window.location.href = `/manage-delegate-from-invitation/login?hash=${encodeURIComponent(this.props.invitationHash)}${this.props.dest ? `&dest=${encodeURIComponent(this.props.dest)}` : ''}${this.props.impersonate ? `&impersonate=${encodeURIComponent(this.props.impersonate)}` : ''}`;
        }
        else {
          this.props.handleRedirectToFfm(this.props.dest || (FFM_CONSOLE_NEXT_WEB_URL + FFM_CONSOLE_NEXT_REDIRECT_PATH));
        }
      } catch (error) {
        this.setState({
          error,
          submitLoading: false,
          manual: true
        });
      }
    }
  };

  /**
   * Though Manual form will validate values,
   * required fields should be dealt with manually
   */
  validateRequired() {
    const { form } = this.state;

    const errors = {};

    if ( !form.location ) {
      errors.location = ERROR_MESSAGES.required;
    }

    if ( !form.name ) {
      errors.name = [ ERROR_MESSAGES.required, ERROR_MESSAGES.name ];
    }

    if ( !_.isEmpty( errors ) ) {
      this.setState( {
        form: update(form, { errors: { $merge: errors } }),
        manual: true
      } );

      return false;
    }

    return true;
  }

  /**
   * Log out user on onboarding cancel
   */
  cancel = (e) => {
    const { logout } = this.props;
    GTM.track("Signup", "Cancelled");

    logout();
  };

  formEmpty() {
    const { form } = this.state;
    return form == null || _.isEqual(_.omit(form, ["errors", "location"]), ARTIST_FIELDS);
  }

  manualFormReady() {
    const { form } = this.state;
    //  No errors, required fields are not empty
    return (
      _.isEmpty(form.errors) && form.name.length > 0 && form.location.length > 0
    );
  }

  checkUserForProfilePicture() {
    const { user } = this.props;

    if ( user && user.profileImage && isUrl( user.profileImage ) ) {
      this.setState({
        form: update(this.state.form, { $merge: { profileImage: user.profileImage } }),
      });
    }
  }

  getClearForm() {
    return {
      ...ARTIST_FIELDS,
      errors: {}
    };
  }

  constructor(props) {
    super(props);

    this.state.form = this.getClearForm();

    this.formFields = Object.keys(ARTIST_FIELDS);
    this.formKey = "form";

    this.validateFieldDebounced = _.debounce(validateField.bind(this), 500);
    this.submitReady = submitReady.bind(this);
    this.formChange = formChange.bind(this);
  }

  componentDidMount() {
    this.presetLocation();
    this.checkUserForProfilePicture();
  }

  componentDidUpdate( prevProps ) {
    const { user } = this.props;
    if ( !_.isEqual( prevProps.user, user ) ) {
      this.checkUserForProfilePicture();
    }
  }

  render() {
    const { intl } = this.props;
    const { manual, review, form, error, selectedArtist, listedArtists, submitLoading } = this.state;
    return (
      <>
        <HeaderContainer>
          <Container row style={{ height: "100%" }}>
            {!manual && (
              <HeaderButton
                onClick={this.cancel}
                variant="transparent"
                text="white"
              >
                <FontAwesomeIcon icon={faTimes} />
                {intl.formatMessage({ id: "CANCEL" })}
              </HeaderButton>
            )}

            {manual && (
              <HeaderButton
                variant="transparent"
                text="white"
                onClick={this.toggleManual}
              >
                <FontAwesomeIcon icon={faArrowLeft} />
                <span>{intl.formatMessage({ id: "BACK" })}</span>
              </HeaderButton>
            )}

            {manual && (
              <HeaderButton
                id="onboarding-submit-button"
                variant="primary-inverted"
                disabled={submitLoading || !this.manualFormReady()}
                onClick={ this.submit }
              >
                { submitLoading && <LoadingContainer id="onboarding-submit-loading"><MetroSpinner size={20} color="#686769"/></LoadingContainer>}
                { !submitLoading && intl.formatMessage({ id: "ONBOARDING_SAVE" })}
              </HeaderButton>
            )}
          </Container>
        </HeaderContainer>

        <Main id="onboarding-artist-container">
          <Container>
            {!manual && (
              <QuickForm
                selectArtist={this.selectArtist}
                setListedArtists={this.setListedArtists}
                clearListedArtists={this.clearListedArtists}
                listedArtists={listedArtists}
                selectedArtist={selectedArtist}
                toggleManual={this.toggleManual}
                disabled={submitLoading}
                error={error}
              />
            )}
            {manual && (
              <ManualForm
                error={error}
                manual={manual}
                review={review}
                formChange={this.formChange}
                setPicture={this.setPicture}
                clearPicture={this.clearPicture}
                locationChange={this.locationChange}
                currencyChange={this.currencyChange}
                form={form}
              />
            )}
          </Container>
        </Main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  invitationHash: state.AcceptInvitation.originalHash,
  dest: state.App.dest,
  impersonate: state.App.impersonate,
});

const mapDispatchToProps = {
  handleRedirectToFfm,
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(Onboarding)));
