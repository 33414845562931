import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { ScaleLoader } from 'react-spinners';
import { injectIntl } from 'react-intl'
import { acceptInvitation, denyInvitation } from "../../../redux/acceptInvitation/actions";

import {
  LoginTitle,
  SingupLink,
  RoleContainer,
  Error,
  LoadingContainer,
  WarningIcon, ConvertRoleDescription, ConvertButton, Separator
} from "../../Login/styled";

class ConvertArtistToPromoterModal extends Component {
  state = {};

  convert = e => {
    this.props.acceptInvitation(true);
  };

  deny = e => {
    this.props.denyInvitation();
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { loading, error, intl } = this.props;
    return (<>
        <RoleContainer>
          <WarningIcon>
            <FontAwesomeIcon icon={ faExclamationTriangle } />
          </WarningIcon>

          <LoginTitle style={{ margin: '1em 0px'}}>
            { intl.messages.CONVERT_ROLE_TITLE }
          </LoginTitle>
          <ConvertRoleDescription>
            { intl.formatMessage( { id: 'CONVERT_ROLE_SUBTITLE' }, {
              br: (<br/>),
              b: (...chunks) => (<b>{chunks}</b>)
            } ) }
          </ConvertRoleDescription>

          <ConvertButton id="button-convert" variant="accent" loading={ loading } onClick={this.convert}>
            { !loading && intl.messages.CONVERT_ROLE_ACCEPT }
            { loading && <LoadingContainer id="button-convert-loading"><ScaleLoader color={'#fff'} /></LoadingContainer> }
          </ConvertButton>

          <Separator style={{marginTop: 15}} text="OR" />

          <SingupLink id="link-deny" onClick={this.deny}>
            <Link to="/#" onClick={(e) => {e.preventDefault(); return false;}}>{ intl.messages.CONVERT_ROLE_DECLINE } →</Link>
          </SingupLink>

          { error && <Error id="auth-error">{error.message}</Error> }

        </RoleContainer>

      </>
    );
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
  loading: state.Auth.loading,
  error: state.Auth.error,
});

const mapDispatchToProps = {
  acceptInvitation,
  denyInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)( injectIntl(ConvertArtistToPromoterModal) );
