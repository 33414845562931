export function getUserAccountName(userData) {
    return (userData.role === 'artist' || !userData.companyName) ? `${userData.firstName} ${userData.lastName || ''}`.trim() : userData.companyName;
};

export function getArtistSocialIcons(artistInfo) {
    // sample: ["Facebook", "Spotify", "Instagram", "Website"]
    const returnedSocialIcons = [];
    if (artistInfo) {
        if (artistInfo.website) returnedSocialIcons.push('Website');
        if (artistInfo.facebook) returnedSocialIcons.push('Facebook');
        if (artistInfo.twitter) returnedSocialIcons.push('Twitter');
        if (artistInfo.youtube) returnedSocialIcons.push('YouTube');
        if (artistInfo.spotifyArtistPage) returnedSocialIcons.push('Spotify');
        if (artistInfo.soundCloudArtistPage) returnedSocialIcons.push('SoundCloud');
        if (artistInfo.tiktokArtistPage) returnedSocialIcons.push('TikTok');
        if (artistInfo.instagram) returnedSocialIcons.push('Instagram');
    }
    return returnedSocialIcons;
};
