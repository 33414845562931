import { all } from 'redux-saga/effects';

import authSagas from './auth/saga';
import appSagas from './app/saga';
import acceptInvitationSagas from './acceptInvitation/saga';
import sendInvitationSagas from './sendInvitation/saga';
import affiliateSagas from './affiliate/saga';

export default function* rootSaga(getState) {
  yield all([
    appSagas(),
    authSagas(),
    acceptInvitationSagas(),
    sendInvitationSagas(),
    affiliateSagas(),
  ]);
}
