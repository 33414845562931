import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import App from './app/reducer';
import Auth from './auth/reducer';
import AcceptInvitation from './acceptInvitation/reducer';
import SendInvitation from './sendInvitation/reducer';

export default history =>
  combineReducers({
    router: connectRouter(history),
    App,
    Auth,
    AcceptInvitation,
    SendInvitation,
  });
