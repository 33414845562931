import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Signup from '../Signup';

class SignupAcceptInvite extends Component {
  state = {
  };

  render() {
    return (
      <Signup
        location={this.props.location}
        fixedEmail={this.props.targetEmail}
        fixedRole={this.props.targetRole}
        routePrefix="/manage-delegate-from-invitation"
        emailAccountOnly
      />
    )
  }
}

const mapStateToProps = (state) => ({
  targetEmail: state.AcceptInvitation.email,
  targetRole: state.AcceptInvitation.isDynamicRole ? undefined : 'promoter',
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(SignupAcceptInvite)
