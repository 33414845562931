import { all, select, call, put, fork, takeEvery } from "redux-saga/effects";

import {
  FFM_CONSOLE_REDIRECT_PATH,
  FFM_CONSOLE_NEXT_REDIRECT_PATH,
  FFM_CONSOLE_WEB_URL,
  FFM_CONSOLE_NEXT_WEB_URL,
} from "@featurefm/common/config/env";

import { routeChanged } from "../../utils/gtm";
import mixpanel from '../../utils/mixpanel'

import authActions from "../auth/actions";
import actions from "./actions";
import AuthService from "@featurefm/common/services/auth";

export const getBasicUserData = (state) => {
  return state.Auth.basicUserData;
};

/**
 * This logs location change in GTM
 */
export function* locationChange() {
  yield takeEvery("@@router/LOCATION_CHANGE", function* (action) {
    const { location } = action.payload;
    routeChanged(location.pathname);
    mixpanel.pageview()
    let routeError = null
    let routeErrorId = null
    let targetEmail = null
    let forceEmail = false

    const sp = new URLSearchParams(location.search);

    if (location.search && sp.get("err")) {
      routeError = sp.get("err")
    } else if (location.search && sp.get("errId")) {
      routeErrorId = sp.get("errId")
    }

    if (location.search && sp.get("email")) {
      targetEmail = sp.get("email")

      if (location.search && sp.get("forceEmail") === 'true') {
        forceEmail = true;
      }
    }

    if (location.search && sp.get("dest")) {
      yield put({
        type: actions.SET_DEST,
        dest: sp.get("dest"),
      })
    }

    if (location.search && sp.get("impersonate")) {
      yield put({
        type: actions.SET_IMPERSONATE,
        impersonate: sp.get("impersonate"),
      })
    }

    yield put({
      type: authActions.SET_AUTH_ERROR,
      error: routeError ? { message: routeError } : null,
      errorId: routeErrorId || null,
      targetEmail,
      forceEmail,
    });

    yield put({
      type: authActions.RESET_SUCCESS_STATE,
    });
  });
}

export function* redirectToConsole() {
  yield takeEvery(actions.REDIRECT_TO_CONSOLE, function* (action) {
    const path = action.path || FFM_CONSOLE_REDIRECT_PATH;

    yield put({
      type: actions.HANDLE_REDIRECT_TO_FFM,
      url: FFM_CONSOLE_WEB_URL + path,
    });
  });
}
export function* redirectToConsoleNext() {
  yield takeEvery(actions.REDIRECT_TO_CONSOLE_NEXT, function* (action) {
    const path = action.path || FFM_CONSOLE_NEXT_REDIRECT_PATH;

    yield put({
      type: actions.HANDLE_REDIRECT_TO_FFM,
      url: action.dest || (FFM_CONSOLE_NEXT_WEB_URL + path),
    });
  });
}

export function* handleRedirectToFfm() {
  yield takeEvery(actions.HANDLE_REDIRECT_TO_FFM, function* ({ url }) {
    // before redirecting to FFM, we need to check if impersonation is required
    const { dest, impersonate } = yield select((state) => state.App);
    const { basicUserData } = yield select((state) => state.Auth);
    const { username, impersonatedFrom, impersonatedFromAdmin } = (basicUserData || {});

    try {
      // un/re/impersonate before navigating to destination url
      if (impersonate && impersonate.toLowerCase() !== (username || '').toLowerCase()) {
        // un-impersonate?
        if (impersonatedFrom) {
          yield call(AuthService.unimpersonate);
        }

        // if we have un-impersonated, then we're currently logged in as the impersonatedFrom user
        if (!impersonatedFrom || impersonate.toLowerCase() !== impersonatedFrom.toLowerCase()) {
          yield call(AuthService.impersonate, {username: impersonate});
        }
      }
    } catch (err) {
      console.error('Error handling impersonation: ', err);
    }

    window.location.href = url;
  });
}

export default function* rootSaga() {
  yield all([
    fork(locationChange),
    fork(redirectToConsole),
    fork(redirectToConsoleNext),
    fork(handleRedirectToFfm),
  ]);
}
