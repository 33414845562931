import actions from './actions';

const initialState = {
  // originalJwt: null,
  isSending: false,
  isSentInvitation: false,
  error: false,
}

export default (state = initialState, action ) => {
  switch (action.type) {

  case actions.SET_SENDING_INVITATION:
    return {
      ...state,
      isSending: true,
      isSentInvitation: false,
      error: null,
    }

  case actions.SET_SENT_INVITATION:
    return {
      ...state,
      isSending: false,
      isSentInvitation: true,
    }

  case actions.SET_SEND_INVITATION_ERROR:
    return {
      ...state,
      isSending: false,
      error: action.error,
      errorData: action.errorData,
    }

  default:
    return state
  }
}
