const actions = {
  //  XHR stuff
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  REGISTER: 'REGISTER',
  RESET_PASSWORD: 'RESET_PASSWORD',
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',

  FB_LOGIN: 'FB_LOGIN',
  SC_LOGIN: 'SC_LOGIN',

  GET_USER: 'GET_USER',
  SET_USER: 'SET_USER',

  //  UI Stuff
  SET_AUTH_LOADING: 'SET_AUTH_LOADING',
  SET_AUTH_LOADED: 'SET_AUTH_LOADED',
  SET_AUTH_ERROR: 'SET_AUTH_ERROR',

  SET_USER_LOADING: 'SET_USER_LOADING',
  SET_USER_ERROR: 'SET_USER_ERROR',

  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  RESET_SUCCESS: 'RESET_SUCCESS',
  AUTH_SUCCESS: 'AUTH_SUCCESS',
  AUTH_RESET: 'AUTH_RESET',

  RESET_SUCCESS_STATE: 'RESET_SUCCESS_STATE',

  SET_BASIC_USER_DATA: 'SET_BASIC_USER_DATA',

  AGGREGATE_ERROR: 'AGGREGATE_ERROR',
}

export function login( username, password, dest ) {
  return {
    type: actions.LOGIN,
    username,
    password,
    dest
  }
}

export function logout() {
  return {
    type: actions.LOGOUT
  }
}

export function register( form ) {
  return {
    type: actions.REGISTER,
    form
  }
}

export function aggregateFieldError ( name, value, error, optFormKey ) {
  return {
    type: actions.AGGREGATE_ERROR,
    name,
    value,
    error,
    formKey: optFormKey,
  }
}

export function registerSuccess() {
  return {
    type: actions.REGISTER_SUCCESS,
  }
}

export function facebookLogin( { role, forceStubs, dest } ) {
  return {
    type: actions.FB_LOGIN,
    role,
    forceStubs,
    dest
  }
}

export function soundCloudLogin( { role, forceStubs, dest } ) {
  return {
    type: actions.SC_LOGIN,
    role,
    forceStubs,
    dest
  }
}

export function resetPassword( email ) {
  return {
    type: actions.RESET_PASSWORD,
    email
  }
}

export function getUser() {
  return {
    type: actions.GET_USER
  }
}

export function checkAuthorization() {
  return { type: actions.CHECK_AUTHORIZATION }
}

export default actions;
