import qs from "qs";

export function getDecodedUserPermissionsInvitationDataFromQueryString(rawQueryString) {
    let qsHash = (qs.parse(rawQueryString, { ignoreQueryPrefix: true }).hash || '');
    let base64EncodedHash = qsHash;
    let decodedString = Buffer.from(base64EncodedHash, 'base64').toString('utf-8');
    let isDynamicRole = decodedString.startsWith('dynamicrole-');
    let decodedDataParts = decodedString.split(/\/(.+)/);

    let hash = decodedDataParts[0]
    let targetEmail = decodedDataParts[1];

    return {
        originalHash: qsHash,
        code: hash,
        email: targetEmail,
        isDynamicRole,
    }
}

export const ERROR_TYPES = {
    EMAIL_MISMATCH: 'EMAIL_MISMATCH',
    ROLE_MISMATCH: 'ROLE_MISMATCH',
    GENERIC_ACCEPT_ERROR: 'GENERIC_ACCEPT_ERROR',
    GENERIC_DENY_ERROR: 'GENERIC_DENY_ERROR',
};
