const actions = {
  SET_AFFILIATE_COOKIE: 'SET_AFFILIATE_COOKIE',
}

export function useAffiliate() {
  return {
    type: actions.SET_AFFILIATE_COOKIE
  }
}

export default actions;
