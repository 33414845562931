import { all, select, call, fork, takeEvery } from "redux-saga/effects";
import qs from "qs";
import AffiliateService from "@featurefm/common/services/affiliate";
import actions from "./actions";

const getLocation = state => {
  return state.router.location;
};

function getDecodedAffiliateFromQueryString(rawQueryString) {
  return (qs.parse(rawQueryString, { ignoreQueryPrefix: true }).affiliate || '');
}

export function* useAffiliate() {
  yield takeEvery(actions.SET_AFFILIATE_COOKIE, function* () {
    try {
      const currentLocation = yield select(getLocation);
      if (currentLocation.search) {
        const affiliate = getDecodedAffiliateFromQueryString(currentLocation.search);
        if (affiliate) {
          yield call(AffiliateService.setCookie, affiliate);
        }
      }
    } catch (err) {
      console.error('Error handling affiliate cookie: ', err);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(useAffiliate),
  ]);
}
