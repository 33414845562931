import React, { Component } from 'react'
import { connect } from 'react-redux'

import { HeaderContainer } from './styled';

export const HeaderContext = React.createContext()

class Header extends Component {
  context
  render() {
    return (
      <HeaderContainer>
        { this.props.children || null }
      </HeaderContainer>
    )
  }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
