const actions = {
  SET_INVITATION_DATA: 'SET_INVITATION_DATA',
  GET_INVITATION_DATA: 'GET_INVITATION_DATA',
  ACCEPT_INVITATION: 'ACCEPT_INVITATION',
  DENY_INVITATION: 'DENY_INVITATION',
  SET_ACCEPTING_INVITATION: 'SET_ACCEPTING_INVITATION',
  SET_ACCEPTED_USER_DELEGATION_INVITATION: 'SET_ACCEPTED_USER_DELEGATION_INVITATION',
  SET_ARTIST_CONVERSION_REQUIRED: 'SET_ARTIST_CONVERSION_REQUIRED',
  SET_USER_DELEGATION_INVITATION_ERROR: 'SET_USER_DELEGATION_INVITATION_ERROR',
}

export function getInvitationData() {
  return {
    type: actions.GET_INVITATION_DATA
  }
}

export function acceptInvitation(bForceConvert) {
  return {
    type: actions.ACCEPT_INVITATION,
    forceConvert: bForceConvert,
  }
}

export function denyInvitation() {
  return {
    type: actions.DENY_INVITATION,
  }
}

export default actions;
