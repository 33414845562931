import React, { Component } from "react";
import { connect } from "react-redux";

import { sendInvitation } from "../../redux/sendInvitation/actions";

import LoadingScreen from "../LoadingScreen";
import SendInviteModal from "./components/SendInviteModal";
import {LoginContainer, AcceptInviteModal as Modal} from "../Login/styled";
import PerfectScrollbar from "react-perfect-scrollbar";
import qs from "qs";

const getDestFromQueryString = (rawQueryString, paramName) => {
  const qsParams = qs.parse(rawQueryString, { ignoreQueryPrefix: true })
  return (qsParams[paramName] || '');
}

class SendInviteLoading extends Component {
  state = {
    dest: getDestFromQueryString(this.props.location.search, 'dest'),
    jwt: getDestFromQueryString(this.props.location.search, 'jwt'),
  };

  AcceptInviteContent() {
    const { appLoading, isSendingInvitation, isSentInvitation, sendError } = this.props;
    const { dest, jwt } = this.state;

    return (
      <Modal id="accept-invite-modal">
        {(appLoading || isSendingInvitation) && (<LoadingScreen />)}
        {!appLoading && !isSendingInvitation && (<SendInviteModal dest={dest} jwt={jwt} sent={isSentInvitation} error={sendError} />)}
      </Modal>
    );
  }

  render() {
    const { mobile, role } = this.props;

    return <>
      {!mobile && (
        <LoginContainer id="accept-invite-container" justifyBreakpoint={ role === 'promoter' ? '780px' : null }>
          <PerfectScrollbar>
            {this.AcceptInviteContent()}
          </PerfectScrollbar>
        </LoginContainer>
      )}

      {mobile && (this.AcceptInviteContent())}
    </>
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
  appLoading: state.App.loading,
  isSendingInvitation: state.SendInvitation.isSending,
  isSentInvitation: state.SendInvitation.isSentInvitation,
  sendError: state.SendInvitation.error,
  location: state.router.location,
});

const mapDispatchToProps = {
  sendInvitation
};

export default connect(mapStateToProps, mapDispatchToProps)(SendInviteLoading);
