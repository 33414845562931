import axios from 'axios';

/**
 * This is what I use everywhere with different axios defaults
 *
 * What this does:
 * 1. Handles errors with toasts (not implemented here yet)
 * 2. Attaches Auth Bearer header (not sure if we need it)
 * 3. Unifies params argument between GET and other requests
 *
 * This will be either modified or dropped :)
 */
export default class ExtendedAxios {
  constructor( { store, baseURL = '/' } ) {
    this.axios = axios.create({
      baseURL,
      withCredentials: true
    });

    if ( store ) {
      this.store = store;
    }
  }

  get(url, params = {}) {
    return this.axios
      .get(url, {
        params,
      })
      .then(this.handleResponse)
      .catch(this.handleError.bind(this));
  }

  post(url, payload = {}) {
    return this.axios
      .post(url, payload)
      .then(this.handleResponse)
      .catch(this.handleError.bind(this));
  }

  patch(url, payload = {}) {
    return this.axios
      .patch(url, payload)
      .then(this.handleResponse)
      .catch(this.handleError.bind(this));
  }

  put(url, payload = {}) {
    return this.axios
      .put(url, payload)
      .then(this.handleResponse)
      .catch(this.handleError.bind(this));
  }

  delete(url) {
    return this.axios
      .delete(url)
      .then(this.handleResponse)
      .catch(this.handleError.bind( this ));
  }

  handleResponse(response) {
    return response.data;
  }

  handleError( e ) {
    //  Errors not always produce
    //  info that's actually useful for us
    const error = {
      status: e.response ? e.response.status : e.status,
      message: e.response ? e.response.data.message : e.message
    }

    if ( this.store ) {
      this.store.dispatch({
        type: "AXIOS_ERROR",
        error,
      });
    }

    return { error };
  }

  getToken() {
    return localStorage.getItem('auth_token');
  }
}
