import styled, { css } from 'styled-components';

import Color from 'color';
import {
  flex,
  flexCol,
  flexRow,
  flexStart,
  breakpointMax,
  flexCenter
} from '@featurefm/common/components/styled/mixins';
import LogoDark from '@featurefm/common/assets/logo/logo-header-darkbg.svg';

export const RowGrid = styled.div`
  ${ flex }
  ${ flexRow }
  ${ flexCenter }
  
  > div {
    margin: 0px 20px;
  }
`

export const Box = styled.div`
  max-width: 480px;
  
  ${ flex }
  ${ flexCol }
  ${ flexStart }
   
  ${ props => props.removeOnMobile ? breakpointMax( 'md', `
    display: none;
  ` ) : `` }
  
`

export const Logo = styled.div`
  width: 160px;
  height: 45px;

  background: url(${ LogoDark  });
  background-size: 100%;
  background-repeat: no-repeat;
`

export const Title = styled.div`
  color: ${ props => props.theme.textMain };
  font-size: 48px;
  line-height: 56px;
  font-weight: 900;
  width: 100%;

  text-align: left;
  margin-top: 70px;    
`

export const SubTitle = styled.div`
  color: ${ props => props.theme.textMain };
  font-size: 22px;
  line-height: 30px;

  text-align: left;
  width: 100%;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 80px;
`

export const TermText = styled.div`
  color: ${ props => props.theme.textMain };
  font-size: 14px;
  line-height: 24px;

  text-align: left;
  width: 100%;
  font-weight: 500;
  margin-top: 0px;    
 
  a {
    color: rgb(76, 165, 219);
  }
`

export const Cancel = styled.div`
  height: 100px;

  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: ${ props => props.theme.textMain };

  a {
    font-weight: 700;
    margin-left: 4px;
    color: ${ props => props.theme.textMain };
  }

  a:hover {
    color: ${ props => Color( props.theme.accent ).lighten(0.15).hex() }
  }
`;
