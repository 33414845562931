import styled from 'styled-components';

import { flex, flexCol, flexCenter, breakpointMax } from './mixins';

export const FlexCenterContainer = styled.div`
  ${ flex }
  ${ flexCol }
  ${ flexCenter }

  width: 100vw;
  height: 100vh;
`;

export const Modal = styled.div`
  ${ flex }
  ${ flexCol }
  ${ flexCenter }

  background: ${ props => props.theme.bgSecondary };
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
`;
