import en from './locales/en';

const defaultLocale = 'en';

const availableLocales = {
  en,
};

const supportedLocales = Object.keys(availableLocales);

/**
 * Get messages for a locale. Return messages for default locale if
 * requested locale is not available
 *
 * @param {string} locale - locale string to look for
 */
function getMessages(locale) {
  if (locale in availableLocales) {
    return availableLocales[locale];
  } else {
    return availableLocales[defaultLocale];
  }
}

export { getMessages, supportedLocales };
