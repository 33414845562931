import GA4React from "ga-4-react";
import ReactGA from 'react-ga';
import Cookies from "universal-cookie";

let ga4 = null;

const initialize = async () => {
  try {
    if (!ga4) {
      ReactGA.initialize('UA-54381400-1');
      const ga4react = new GA4React("G-8RFWMHKNDE");
      ga4 = await ga4react.initialize()
    }
  } catch (e) {
    console.warn(e)
  }
}

const pageview = async ( url ) => {
  try {
    await initialize();
    ReactGA.pageview(url);
    ga4.pageview(url)
  } catch (e) {
    console.warn(e);
  }
}

const trackEvent = async ( category = '',  action = '', label ='' ) => {
  try {
    await initialize();
    ReactGA.event({category,  action, label});
    ga4.event(action, label, category);
  } catch (e) {
    console.warn(e);
  }
}

const getPlanLabel = () => {
  let planLabel = "Free Plan";
  try {
    const cookies = new Cookies();
    const selectedOnBoardingPlan = cookies.get('selectedOnBoardingPlan');
    switch (selectedOnBoardingPlan) {
      case 'artist':
        planLabel = 'Artist - Monthly'
        break;
      case 'artist_annual':
        planLabel = 'Artist - Annual'
        break;
      case 'proartist':
        planLabel = 'Pro Artist - Monthly'
        break;case 'artist':
      case 'proartist_annual':
        planLabel = 'Pro Artist - Annual'
        break;
        planLabel = 'Artist - Annual'
        break;
      case 'marketer':
        planLabel = 'Marketer - Monthly'
        break;
      case 'marketer_annual':
        planLabel = 'Marketer - Annual'
        break;
      case 'promarketer':
        planLabel = 'Pro Marketer - Monthly'
        break;
      case 'promarketer_annual':
        planLabel = 'Pro Marketer - Annual'
        break;
      default:
        break;
    }
  } catch (err) {}

  return planLabel;
}

export default {
  pageview,
  trackEvent,
  initialize,
  getPlanLabel,
}
