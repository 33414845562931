import React from "react";

import { Wrapper, Logo as LogoImg, Text } from "./styled";

export default function Logo(props) {
  return (
    <Wrapper {...props}>
      <LogoImg />
      <Text>All-in-one music marketing platform</Text>
    </Wrapper>
  );
}
