import styled from 'styled-components';

import {
  flex,
  flexStart,
  breakpointMax,
} from '@featurefm/common/components/styled/mixins';
import { Container } from '../../Main.styled';

export const HeaderContainer = styled.header`
  position: fixed;
  z-index: 1090;

  top: 0px;
  left: 0px;

  width: 100%;
  height: 72px;

  background: ${ props => props.theme.bgDark };

  ${ Container } {
    justify-content: space-between;
    align-items: center;
  }

  ${ breakpointMax( 'md', `
    padding: 0px 16px;
  ` ) }
`
export const HeaderLogoContainer = styled.div`
  ${ flex }
  ${ flexStart }

  align-items: center;

  max-width: 1030px;
  margin: 0 auto;
  ${ props => props.noPadding ? '' : 'padding-left: 16px;' }

  position: relative;
  height: 100%;
`
