import Color from 'color';
import { COLORS } from './index';

//  These don't change in theme yet
export const legacy = {
  name: 'legacy',
  logoStyle: 'dark',
  fontFamily: `'museo_sans_cyrl', sans-serif`,
  textMain: COLORS.dusk,
  mutedText: COLORS.legacyMuteGrey,
  primary: COLORS.blue,
  accent: COLORS.pink,
  danger: COLORS.danger,
  success: COLORS.success,
  bgDefault: COLORS.legacyLight,
  bgSecondary: COLORS.white,
  bgDark: COLORS.legacyDark,
  inputBgError: Color( COLORS.danger ).saturationv( 4 ).hex(),
  inputBgFocus: COLORS.muteBlueish,
  inputBgIdle: COLORS.white,
  inputBorderIdle: COLORS.greyLight,
  inputBorderFocus: COLORS.purple,
  inputPrefixBgIdle: COLORS.greyLightest,
  inputPrefixBgFocus: COLORS.purple,
  borderDefault: COLORS.legacyGreyBorder
}

export const light = {
  name: 'light',
  logoStyle: 'light',
  textMain: COLORS.dusk,
  mutedText: COLORS.muteDusk,
  primary: COLORS.purple,
  accent: COLORS.purple,
  danger: COLORS.danger,
  success: COLORS.success,
  bgDefault: COLORS.greyLightest,
  bgSecondary: COLORS.white,
  inputBgError: Color( COLORS.danger ).saturationv( 4 ).hex(),
  inputBgFocus: COLORS.muteBlueish,
  inputBgIdle: COLORS.greyLightest,
  inputBorderIdle: COLORS.greyLight,
  inputBorderFocus: COLORS.purple,
  borderDefault: COLORS.legacyGreyBorder
}

export const ada = {
  name: 'ada',
  logoStyle: 'dark',
  textMain: COLORS.white,
  mutedText: Color( COLORS.white ).alpha(0.3).rgb().string(),
  primary: COLORS.blue,
  accent: COLORS.ada,
  danger: COLORS.danger,
  success: COLORS.success,
  bgDefault: COLORS.darkBgGradient,
  bgSecondary: COLORS.greyDarkest,
  inputBgError: COLORS.greyDark,
  inputBgFocus: COLORS.greyDark,
  inputBgIdle: COLORS.greyDark,
  inputBorderIdle: COLORS.grey,
  inputBorderFocus: COLORS.ada,
  borderDefault: COLORS.legacyGreyBorder
}

export const dark = {
  name: 'dark',
  logoStyle: 'dark',
  textMain: COLORS.white,
  mutedText: Color( COLORS.white ).alpha(0.3).rgb().string(),
  primary: COLORS.purple,
  accent: COLORS.purple,
  danger: COLORS.danger,
  success: COLORS.success,
  bgDefault: COLORS.darkBgGradient,
  bgSecondary: COLORS.greyDarkest,
  inputBgError: COLORS.greyDark,
  inputBgFocus: COLORS.greyDark,
  inputBgIdle: COLORS.greyDark,
  inputBorderIdle: COLORS.grey,
  inputBorderFocus: COLORS.purple,
  borderDefault: COLORS.legacyGreyBorder
}

export default {
  light, dark, ada, legacy
}
