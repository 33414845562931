import React, { useState } from 'react'
import _ from 'lodash';

import { InputGroup, InputContainer, Input, InputError, InputPrefix, FormGroup, FormLabel, LoadingContainer } from './styled';
import { MetroSpinner } from "react-spinners-kit";

export default function FormInput(props) {
  const [focused, setFocused] = useState(false);

  function onFocus(e) {
    setFocused( true )
  }

  function onBlur(e) {
    setFocused( false )
  }

  function onKeyDown(e) {
    if ( e.key === "Enter" ) {
      e.preventDefault()
    }
  }

  return (
    <InputGroup>
      <InputContainer focused={ focused } error={ props.error }>
        { props.prefix &&
          <InputPrefix>
            { props.prefix }
          </InputPrefix>
        }

        <Input {...props} onFocus={ onFocus } onBlur={ onBlur } onKeyDown={ onKeyDown }/>

        {props.loading && <LoadingContainer className={`input-loading-container_${ props.name }`}><MetroSpinner size={20} color="#9B62FF"/></LoadingContainer>}

      </InputContainer>

      { props.error && <InputError className={ `input-error_${ props.name }` }>{ _.isArray( props.error ) ? "∙" + props.error.join('\n∙') : props.error }</InputError> }
    </InputGroup>
  )
}

export {
  FormGroup,
  FormLabel
}
