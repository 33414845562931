import React, { Component } from "react";
import { connect } from "react-redux";

import LoadingScreen from "../LoadingScreen";
import EmailConfirmationSuccess from "./components/EmailConfirmationSuccess";
import EmailConfirmationError from "./components/EmailConfirmationError";
import {LoginContainer, AcceptInviteModal as Modal} from "../Login/styled";
import PerfectScrollbar from "react-perfect-scrollbar";
import Header from "../../components/common/Header";
import { HeaderLogoContainer } from "../../components/common/Header/styled";
import { Logo } from "../AcceptTerms/styled";

class EmailConfirmationLoading extends Component {
  state = {
  };

  EmailConfirmationContent() {
    const { appLoading, confirmationErrorId } = this.props;

    return (
      <Modal id="accept-invite-modal">
        {(appLoading) && (<LoadingScreen />)}
        {!appLoading && confirmationErrorId && (<EmailConfirmationError />)}
        {!appLoading && !confirmationErrorId && (<EmailConfirmationSuccess />)}
      </Modal>
    );
  }

  render() {
    const { mobile, role } = this.props;

    return <>
      {!mobile && (
        <LoginContainer id="accept-invite-container" justifyBreakpoint={ role === 'promoter' ? '780px' : null }>
          <PerfectScrollbar>
            <Header><HeaderLogoContainer><Logo /></HeaderLogoContainer></Header>
            {this.EmailConfirmationContent()}
          </PerfectScrollbar>
        </LoginContainer>
      )}

      {mobile && (<>
        <Header><HeaderLogoContainer noPadding><Logo /></HeaderLogoContainer></Header>
        {this.EmailConfirmationContent()}
      </>)}
    </>
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
  appLoading: state.App.loading,
  confirmationErrorId: state.Auth.errorId,
});

export default connect(mapStateToProps)(EmailConfirmationLoading);
