import { css } from 'styled-components';
import Color from 'color';
import { SCREENS } from '../../config';

export const flex = css`
  display: flex;
`;

export const flexCol = css`
  flex-direction: column;
`;

export const flexRow = css`
  flex-direction: row;
`;

export const flexCenter = css`
  justify-content: center;
  align-items: center;
`;

export const flexStart = css`
  justify-content: flex-start;
  align-items: flex-start;
`;

export const pinAbsolute = css`
  position: absolute;

  width: 100%;
  height: 100%;

  top: 0px;
  left: 0px;

`

const TRANSITIONS = {
  short: '.15s',
  common: '.3s',
  longer: '.6s',
  long: '1s',
  longest: '3s'
};

const DEFAULT_TRANSITION_PROPERTIES = `background-color, opacity, transform`;

export const transition = (length, properties = false) => {
  return css`
    transition: all ${ TRANSITIONS[ length ] } ease;

    ${ properties ? `
      transition-property: ${ properties };
    ` : `
      transition-property: ${ DEFAULT_TRANSITION_PROPERTIES };
    `}
  `
}

export const breakpointMin = ( screen, styles ) => {
  return css`
    @media (min-width: ${ SCREENS[ screen ] + 1 }px) {
      ${ styles }
    }
  `;
}

export const breakpointMax = ( screen, styles ) => {
  return `
    @media (max-width: ${ SCREENS[ screen ] }px) {
      ${ styles }
    }
  `;
}

export const buttonReset = background => css`
  background: ${ background };
  outline: none;
  border: none;
`

export const aspectRatio = ( w, h ) => css`
  position: relative;

  :before {
    display: block;
    content: "";
    width: 100%;
    padding-top: ${ (h / w) * 100 }%;
  }

  > .content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

/**
 * Getting slightly tired of typing
 * this out each time 🙂
 *
 * @param {String} color
 * @param {Number} alpha
 */
export function rgba( color, alpha ) {
  return Color( color ).alpha( alpha ).rgb().string();
}
