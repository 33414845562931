import styled, { createGlobalStyle } from 'styled-components';
import { flex, flexCol } from '@featurefm/common/components/styled/mixins';

export const GlobalStyle = createGlobalStyle`
  html {
    overscroll-behavior: none;

    ${ props => props.overscrollBg ? `
      background: ${ props.theme[ props.overscrollBg ] };
    ` : `` }
  }

  body.ffm-app,
  body.ffm-app * {
    font-family: ${ props => props.theme.fontFamily ? props.theme.fontFamily : "'Montserrat', sans-serif" };
  }
`;

export const Container = styled.div`
  ${ flex }
  ${ props => props.row ? '' : flexCol }

  max-width: 1030px;
  margin: 0 auto;
`

export const Main = styled.section`
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-top: ${ props => props.noHeader ? '0px' : '72px' };

  background: ${ props => props.theme.bgDefault };
`

export const Button = styled.button``;