import React from 'react'

import { AccountTypeDropdownLabel } from '../styled';

export default function AccountTypeLabel({id,accountType}) {

  return (
    <AccountTypeDropdownLabel id={ id || `account-type-option-${ accountType.code }`}>
      <span>{ accountType.name }</span>
    </AccountTypeDropdownLabel>
  )
}
