import _ from 'lodash';

import { SCREENS } from '@featurefm/common/config';

const actions = {
  RESIZE: 'RESIZE',
  SWITCH_THEME: 'SWITCH_THEME',
  SET_DEST: 'SET_DEST',
  SET_IMPERSONATE: 'SET_IMPERSONATE',
  SET_LOADED: 'SET_LOADED',
  SET_LOADING: 'SET_LOADING',

  REDIRECT_TO_CONSOLE: 'REDIRECT_TO_CONSOLE',
  REDIRECT_TO_CONSOLE_NEXT: 'REDIRECT_TO_CONSOLE_NEXT',

  HANDLE_REDIRECT_TO_FFM: 'HANDLE_REDIRECT_TO_FFM'
}

const MOBILE_VIEWS = ['sm', 'md'];
export const EXTENDED_MOBILE = ['sm', 'md', 'lg'];

/**
 * returns true if mobile view needs to be rendered here and now
 *
 * @returns {boolean}
 */
export function isMobile(view, mobileViews = []) {
  const checkViews = mobileViews.length > 0 ? mobileViews : MOBILE_VIEWS;
  return checkViews.includes(view);
}

//  Set all viewport related properties on resize
export function resize(width, height, collapsedBreakpoint) {
  return {
    type: actions.RESIZE,
    collapsed: width < collapsedBreakpoint,
    width,
    height,
  };
};

export function getView(width) {
  let view = '3xl';

  _.each(Object.keys(SCREENS).reverse(), key => {
    if (width <= parseInt(SCREENS[key], 10)) {
      view = key;
    }
  });

  return view;
};

export function switchTheme( theme ) {
  return {
    type: actions.SWITCH_THEME,
    theme
  }
}

export function redirectToConsole( path = false ) {
  return {
    type: actions.REDIRECT_TO_CONSOLE,
    path
  }
}

export function redirectToConsoleNext( path = false ) {
  return {
    type: actions.REDIRECT_TO_CONSOLE_NEXT,
    path
  }
}

export function handleRedirectToFfm( url ) {
  return {
    type: actions.HANDLE_REDIRECT_TO_FFM,
    url
  }
}

export default actions;
