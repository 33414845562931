import Color from "color";

export const SCREENS = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  "2xl": 1440,
  "3xl": 1920,
};

export const COLORS = {
  //  Some specific colors
  dusk: "#414754",
  pink: "#FD437D",
  muteDusk: "#D8D8D8",
  danger: "#F43131",
  success: "#35D36E",

  //  Legacy colors
  legacyDark: "#1F2532",
  legacyLight: "#F9FAFC",
  legacyGreyBorder: "#EBECED",
  legacyMuteGrey: "#B3B5BB",

  //  White
  white: "#FFF",

  //  Brand
  facebook: "#3B5998",

  soundcloud: "#F26F23",

  //  Brand
  ada: "#C6262D",

  //  Greys
  darkBgGradient: "linear-gradient(90deg, #2C2C2C 0%, #000000 100%)",
  greyLightest: "#FBFBFB",
  greyLight: "#E8E9EB",
  grey: "#444444",
  greyDark: "#2E2E2E",
  greyDarkest: "#242424",

  //  Blues
  blue: "#1AA7E0",
  blueGradient: "linear-gradient( #00F2FE, #4FACFE )",
  muteBlueish: "#F5FCFF",

  //  Pinks
  pinkGradient:
    "linear-gradient(190deg, #A445B2 0%, #D41872 51.71%, #FF0066 100%)",
  pinkGradientHover: `linear-gradient(
    190deg,
    ${Color("#A445B2").lighten(0.2).hex()} 0%,
    ${Color("#D41872").lighten(0.2).hex()} 51.71%,
    ${Color("#FF0066").lighten(0.2).hex()} 100%
  )`,
  purple: "#9B62FF",
  green: "#58ECCA"
};

export const ARTIST_TYPES = {
  ARTIST: "artist",
  BAND: "band",
};

export const BUCKET_TYPES = {
  IMAGES_BUCKET: "images_bucket",
  SONGS_BUCKET: "songs_bucket",
  FB_CREATIVES_BUCKET: "fb_creatives_bucket",
  TEST_BUCKET: "test_bucket",
};

export const DEFAULT_LOCATION = "us-ca";

export const LOGIN_LG_SCALE = 0.85;

export const CURRENCIES = [
  {
    text: "$ USD",
    code: "USD",
    validInSignup: true,
    validForBudgetPackages: true,
    minCPM: 20,
  },
  {
    text: String.fromCharCode(8364) + " Euro",
    code: "EUR",
    validInSignup: true,
    validForBudgetPackages: true,
    minCPM: 10,
  },
  {
    text: String.fromCharCode(163) + " GBP",
    code: "GBP",
    validInSignup: true,
    validForBudgetPackages: false,
    minCPM: 15,
  },
  {
    text: "R$ BRL",
    code: "BRL",
    validInSignup: false,
    validForBudgetPackages: false,
    minCPM: 60,
  },
  {
    text: "$ MXN",
    code: "MXN",
    validInSignup: false,
    validForBudgetPackages: false,
    minCPM: 370,
  },
];

export const DEFAULT_CURRENCY = "USD";

export const EUR_CURRENCY = [
  "at",
  "be",
  "cy",
  "ee",
  "fi",
  "fr",
  "de",
  "gr",
  "ie",
  "it",
  "lv",
  "lt",
  "lu",
  "mt",
  "nl",
  "pt",
  "sk",
  "si",
  "es",
];

export const GBP_CURRENCY = [ 'gb' ];

export const CONTACT_US_URL = "mailto:support@feature.fm";
export const TOS_URL = "https://ffm.to/terms";
export const PP_URL = "https://ffm.to/privacypolicy";
