import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import PerfectScrollbar from "react-perfect-scrollbar";
import qs from "qs";

import { registerSuccess } from "../../redux/auth/actions";
import { FFM_CONSOLE_API_URL } from "@featurefm/common/config/env";

import { LoginContainer } from "../Login/styled";

import SignupModal from "./components/SignupModal";
import { getCurrencyFromQueryString } from "@featurefm/common/helpers/onboarding";
import analytics from "@featurefm/common/helpers/analytics";
import ExtendedAxios from "@featurefm/common/helpers/axios";

const getValidRoleFromQueryString = (rawQueryString) => {
  const qsRole = (qs.parse(rawQueryString, { ignoreQueryPrefix: true }).role || '').toLowerCase();
  if (qsRole === 'artist') return  qsRole
  if (['promoter', 'label'].indexOf(qsRole) !== -1) {
    return 'promoter';
  }

  return null;
}

const getValidPlanFromQueryString = (rawQueryString) => {
  const qsPlan = (qs.parse(rawQueryString, { ignoreQueryPrefix: true }).plan || '').toLowerCase();
  if (['free',
    'free_annual',
    'artist',
    'artist_annual',
    'proartist',
    'proartist_annual',
    'marketer',
    'marketer_annual',
    'promarketer',
    'promarketer_annual'].indexOf(qsPlan) !== -1) {
    return qsPlan;
  }

  return null;
}

const getVariantFromQueryString = (rawQueryString) => {
  return (qs.parse(rawQueryString, { ignoreQueryPrefix: true }).variant || '').toLowerCase();
}

class Signup extends Component {
  state = {
    fixedRole: this.props.fixedRole || getValidRoleFromQueryString(this.props.location.search),
    role: null,
    currency: getCurrencyFromQueryString(this.props.location.search),
    plan: getValidPlanFromQueryString(this.props.location.search),
    variant: getVariantFromQueryString(this.props.location.search),
  };

  selectRole = (role) => {
    const roleLabel = role === 'promoter'
        ? 'label'
        : 'artist';

    try {
      analytics.pageview(`${window.location.pathname}/${roleLabel}/${window.location.search}`)
    } catch (e) {
      console.warn(e)
    }

    const sp = new URLSearchParams(this.props.location.search);
    const { registerSuccess } = this.props;

    if (sp.get("from") === 'symphonic' && sp.get("jwt") !== '') {
      registerSuccess()
      setTimeout(() => {
        window.top.location.href = `${FFM_CONSOLE_API_URL}/jwtLogin?jwt=${sp.get("jwt")}&role=${role}`
      }, 0)
    } else {
      this.setState({ role });
      this.props.history.replace(`${this.props.history.location.pathname}?role=${roleLabel}${sp.get("hash") ? `&hash=${encodeURIComponent(sp.get("hash"))}` : ''}`);
    }
  };

  componentDidMount() {
    const { role, fixedRole, currency, plan, variant } = this.state;
    if (plan) {
      const eventId = uuidv4()

      try {
        if (typeof fbq !== 'undefined') {
          // eslint-disable-next-line no-undef
          fbq('track', 'AddToCart', {
            userType: fixedRole || role,
            variant,
            plan,
            currency,
          }, { eventID: eventId });
        }

        const cookieValue = (name) => document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
        const fbc = cookieValue('_fbc') || null;
        const fbp = cookieValue('_fbp') || null;

        const axios = new ExtendedAxios({baseURL: FFM_CONSOLE_API_URL});
        axios.post( '/facebook-conversion', {
          name: 'AddToCart',
          fbc,
          fbp,
          eventId,
          plan,
          currency,
          userType: fixedRole || role,
          variant,
        })
      } catch (ex) {
        console.error("cannot send initiate checkout event to facebook", ex)
      }
    }

    if (fixedRole && plan) {
      this.selectRole(fixedRole);
    }
  }

  render() {
    const { mobile, fixedEmail, routePrefix, targetEmail, forceEmail, emailAccountOnly } = this.props;
    const { role, fixedRole, currency, variant, plan } = this.state;

    const effectiveFixedEmail = fixedEmail || (forceEmail ? targetEmail : null);

    return (
      <>
        {!mobile && (
          <LoginContainer id="signup-container" justifyBreakpoint={ (fixedRole || role) === 'promoter' ? '780px' : null }>
            <PerfectScrollbar>
              <SignupModal
                role={fixedRole || role}
                selectRole={this.selectRole}
                isFixedRole={!!fixedRole}
                currency={currency}
                plan={plan}
                variant={variant}

                fixedEmail={effectiveFixedEmail}
                routePrefix={routePrefix}
                queryString={this.props.location.search}

                emailAccountOnly={emailAccountOnly}
              />
            </PerfectScrollbar>
          </LoginContainer>
        )}

        {mobile && (
          <SignupModal
            role={fixedRole || role}
            selectRole={this.selectRole}
            isFixedRole={!!fixedRole}
            currency={currency}
            plan={plan}

            fixedEmail={effectiveFixedEmail}
            routePrefix={routePrefix}
            queryString={this.props.location.search}

            emailAccountOnly={emailAccountOnly}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
  targetEmail: state.Auth.targetEmail,
  forceEmail: state.Auth.forceEmail,
});

const mapDispatchToProps = {
  registerSuccess
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Signup));
