import React, { useState, useRef } from "react";
import _ from "lodash";
import Select, { createFilter } from "react-select";

import {
  InputGroup,
  Input,
  InputError,
  InputPrefix,
  FormGroup,
  FormLabel
} from "../FormInput/styled";
import { DropdownContainer, DropdownMenuGlobalStyles } from "./styled";

function escapeRegExp(text) {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\\]/g, '\\$&');
}

export default function FormDropdown(props) {
  const [focused, setFocused] = useState(false);
  const selectPortalTarget = useRef();

  function onMenuOpen(e) {
    setFocused(true);
  }

  function onMenuClose(e) {
    setFocused(false);
  }

  const filterOption = (candidate, input) => {
    const { filterProperties } = props;

    if ( input && filterProperties ) {
      const regex = new RegExp( escapeRegExp(input), "ig" );

      for (let index = 0; index < filterProperties.length; index++) {
        const prop = filterProperties[index];
        const match = _.get( candidate, prop ).match( regex );

        if ( match ) {
          return true;
        }
      }

      return false;
    }

    return true;
  }

  return (
    <InputGroup style={{ position: 'relative' }} ref={ selectPortalTarget }>
      <DropdownContainer focused={focused} error={props.error}>
        {props.prefix && <InputPrefix>{props.prefix}</InputPrefix>}

        <Select
          {...props}
          onMenuOpen={onMenuOpen}
          onMenuClose={onMenuClose}
          menuPortalTarget={ document.body }
          className="ffm-form-select"
          classNamePrefix="ffm-form-select"
          filterOption={filterOption}
          isDisabled={ !props.options }
        />
      </DropdownContainer>

      {props.error && (
        <InputError>
          {_.isArray(props.error) ? "∙" + props.error.join("\n∙") : props.error}
        </InputError>
      )}
    </InputGroup>
  );
}

export { FormGroup, FormLabel, DropdownMenuGlobalStyles };
