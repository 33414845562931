import styled from 'styled-components';
import Color from 'color';

import { FlexCenterContainer } from '@featurefm/common/components/styled';
import { flex, flexCol, flexCenter, buttonReset, breakpointMax } from '@featurefm/common/components/styled/mixins';
import { InputGroup, InputContainer, Input, InputPrefix, InputError, FormGroup, FormLabel } from '@featurefm/common/components/FormInput/styled';

import { Main as MainContainer, Container } from '../../components/Main.styled'
import { Button as LoginButton, Separator, LoadingContainer } from '../Login/styled'


/**
 * Some of these can be common if we are to
 * continue making page components in React
 *
 * Login has specific styling more akin to Landing Page
 * whereas Console has more "serious" styling and different color
 * scheme.
 */

export const Main = styled( MainContainer )`
  ${ Container } {
    ${ flexCenter }
    padding: 64px 0px;
    min-height: calc( 100vh - 72px );
  }

  ${ breakpointMax( 'md', `
    ${ Container } {
      padding: 48px 0px;
    }
  ` ) }
`;

export const Title = styled.div`
  font-size: 30px;
  line-height: 35px;

  color: ${ props => props.theme.textMain };

  ${ breakpointMax( 'md', `
    width: 90%;
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  ` ) }
`

export const Subtitle = styled.div`
  width: 60%;
  max-width: 480px;

  font-size: 16px;
  line-height: 24px;

  margin: 1em 0px;

  text-align: center;

  color: ${ props => Color( props.theme.textMain ).alpha( 0.4 ).rgb().string() };

  mix-blend-mode: normal;

  ${ breakpointMax( 'md', `
    font-size: 16px;
    line-height: 22px;
    width: 90%;
    margin: 1em 0px 0px;
  ` ) }

`

//  This Form component can be modified to
//  be universal and hold altered input styles
//  for any Console Page
export const Form = styled.form`
  ${ flex }
  ${ flexCol }

  background: ${ props => props.theme.bgSecondary };
  box-shadow: 0px 0px 14px rgba(65, 71, 84, 0.08);
  border-radius: 8px;

  width: ${ props => props.manual ? '78%' : '60%' };
  max-width: ${ props => props.manual ? '744px' : '620px' };

  padding: 64px 64px 0px;
  margin-top: 32px;

  ${ FormGroup } {
    position: relative;
  }

  ${ FormGroup } ${ InputGroup },
  ${ FormGroup } ${ LoginButton } {
    position: relative;
    min-height: 0px;
  }

  ${ FormGroup } ${ InputContainer },
  ${ FormGroup } ${ LoginButton } {
    border-radius: 6px;
    height: 48px;
  }

  ${ FormGroup } ${ Input },
  ${ FormGroup } .ffm-form-select {
    font-size: 16px;
    line-height: 18px;
  }

  ${ FormGroup } ${ Input },
  ${ FormGroup } .ffm-form-select__control {
    border-radius: 5px;
  }

  ${ FormGroup } ${ InputGroup } {
    flex-grow: 1;
    max-width: none;
  }

  ${ FormGroup } ${ InputContainer } {
    padding: 1px;
  }

  ${ FormGroup } ${ InputPrefix } {
    margin-right: 1px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  ${ FormGroup } ${ LoginButton } {
    width: 106px;
    margin-left: 12px;
  }

  ${ Separator } {
    height: 20px;
    margin-top: 26px;

    :before {
      background: ${ props => props.theme.borderDefault };
    }
  }

  ${ LoginButton }[type="transparent"] {
    height: 30px;
    text-transform: none;
  }

  ${ FlexCenterContainer } {
    width: 100%;
    height: 120px;

    padding-bottom: 10px;

    flex-grow: 1;
  }

  ${ props => props.manual ? `
    padding-bottom: 64px;

    ${ FormGroup } {
      align-items: flex-start;
      justify-content: flex-start;

      :not(:last-child) {
        margin-bottom: 18px;
      }
    }

    ${ FormGroup } ${ InputGroup } {
      width: 74%
    }

    ${ FormGroup } ${ FormLabel } {
      ${ flex }

      align-items: center;

      width: 26%;
      height: 48px;

      font-weight: 600;

    }
  ` : `
    ${ FormGroup } ${ InputError } {
      position: absolute;
      bottom: -24px;
      left: 0px;
    }
  ` };

  ${ breakpointMax( 'md', `
    width: 96%;
    padding: 16px;
    margin-top: 48px;

    ${ FormGroup } {
      ${ flexCol }

      margin-bottom: 0px !important;
    }

    ${ FormGroup } ${ FormLabel },
    ${ FormGroup } ${ InputGroup },
    ${ FormGroup } ${ LoginButton } {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }

    ${ FormGroup } ${ LoginButton } {
      margin-top: 8px;
    }

    ${ FlexCenterContainer } {
      height: 80px;
      padding-bottom: 0px;
    }

    ${ Separator } {
      margin-top: 16px;
    }
  ` )}
`

export const ListedArtistsContainer = styled.div`
  ${ flex }
  
  flex-direction: column;
  overflow: scroll;
  border: 1px solid #E8E9EB;
  max-height: 210px;
  margin-top: 26px;
  border-radius: 6px;  
  
`;

export const ListedArtistsMessage = styled.div`
  height: 48px;
  min-height: 48px;
  flex-grow: 1;

  ${ flex }

  align-items: center;

  font-size: 16px;
  line-height: 18px;

  padding: 0px 16px;

  background: ${ props => Color( props.theme.primary ).alpha( 0.1 ).rgb().string() };
  color: ${ props =>props.theme.primary };
`;

export const ListedArtist = styled.div`
  height: 62px;
  min-height: 62px;
  flex-grow: 1;

  ${ flex }

  justify-content: space-between;
  align-items: center;

  font-size: 14px;
  line-height: 18px;

  padding: 0px 16px;

  background: ${ props => Color( props.theme.primary ).alpha( 0.1 ).rgb().string() };
  color: ${ props =>props.theme.primary };

  & > span {
    flex-grow: 1;
  }
  
  & > span > a {
    margin-left: 5px;
  }
  
  &:not(:last-child) {
    border-bottom: 1px solid ${ props =>props.theme.borderDefault };
  }

  button {
    ${ buttonReset( 'transparent' ) }
    
    border: 1px solid ${ props =>props.theme.primary };
    padding: 4px 10px;
    color: ${ props =>props.theme.primary };
    border-radius: 4px;
  }

  button:disabled,
  button[disabled] {
    pointer-events: none;
    color: ${ props => Color( props.theme.primary ).alpha( 0.25 ).rgb().string() } !important;
    border: 1px solid ${ props => Color( props.theme.primary ).alpha( 0.25 ).rgb().string() } !important;
  }  

  button:hover {
    background-color: ${ props =>props.theme.primary };
    color: white;  
  }

  :before {
    content: " ";
    display: block;

    width: ${ props => props.image ? '36px' : '0px' };
    height: 36px;

    background: url(${ props => props.image });
    background-size: 100%;

    border-radius: 10px;

    margin-right: 10px;
  }
`;

export const HeaderButton = styled( LoginButton )`
  width: auto;
  height: 48px;
  text-transform: none;
  border-radius: 6px;
  font-size: 14px;

  ${ props => props.variant === 'transparent' && `
    width: auto;
    padding: 0px;


    .svg-inline--fa {
      width: 18px;
      height: 18px;

      margin-right: 16px;
    }

    ${ breakpointMax( 'md', `
      & span {
        display: none;
      }
    ` ) }
  ` }

  ${ props => props.variant === 'primary-inverted' && `
    padding: 0px 2em;

    ${ LoadingContainer } {
      position: relative;
      min-width: 154px;
    }

    ${ LoadingContainer } div:before {
      background-color: ${ props.theme.primary };
    }

    ${ breakpointMax( 'md', `
      padding: 0px 1em;

      ${ LoadingContainer } {
        min-width: 132px;
      }
    ` ) }
  ` }

  ${ breakpointMax( 'md', `
    margin-top: 0px !important;
    font-size: 12px;
    height: 46px;
  ` ) }
`

export const menuStyleOverride = {
  fontSize: 14,
  borderRadius: 6,
  borderWidth: 1,
  optionHeight: 48
}

export const Error = styled.div`
  margin: 2em auto 0px;

  font-size: 13px;
  font-weight: 500;

  width: 60%;

  text-align: center;

  color: ${ props => props.theme.danger };

  ${ breakpointMax( 'md', `
    width: 96%;
  ` ) }
`

export const LocationDropdownLabel = styled.div`
  ${ flex }

  width: 100%;
  height: 100%;

  align-items: center;
`

export const AccountTypeDropdownLabel = styled.div`
  ${ flex }

  width: 100%;
  height: 100%;

  align-items: center;
`
