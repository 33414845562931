const actions = {
  SEND_INVITATION: 'SEND_INVITATION',
  SET_SENDING_INVITATION: 'SET_SENDING_INVITATION',
  SET_SENT_INVITATION: 'SET_SENT_INVITATION',
  SET_SEND_INVITATION_ERROR: 'SET_SEND_INVITATION_ERROR',
}

export function sendInvitation(jwt) {
  return {
    type: actions.SEND_INVITATION,
    jwt,
  }
}

export default actions;
