import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { MetroSpinner } from "react-spinners-kit";
import { injectIntl } from "react-intl";

import FormInput, { FormGroup } from "@featurefm/common/components/FormInput";
import { FlexCenterContainer } from "@featurefm/common/components/styled";
import Validators, {ERROR_MESSAGES} from "@featurefm/common/validators";

import { Separator, Button } from "../../Login/styled";
import { Title, Subtitle, Form, ListedArtistsContainer, ListedArtistsMessage, ListedArtist, Error } from "../styled";
import MiscService from "@featurefm/common/services/misc";
import { processResolverResponse, processSpotifyArtistsResponse } from "@featurefm/common/helpers/onboarding";

const miscService = new MiscService();

class QuickFormArtist extends Component {
  state = {
    link: "",
    linkError: null,
    loading: false
  };

  searchTermChange = (e) => {
    const link = e.target.value;

    this.validateArtistSearchTermDebounced(link);
    this.setState({ link, loading: !!link, linkError: null });

    this.clearListedArtists();
  };

  validateArtistSearchTerm = (value) => {
    const valid = !Validators.url(value) || Validators.artistLink(value);  // either non-URL or valid artist url

    if (value && valid !== true) {
      this.setState({ linkError: ERROR_MESSAGES.artist, loading: false });
    } else {
      this.setState({ linkError: null });
      if (value) {
        this.submit();
      }
    }
  };

  queryTrackFinder = async (url) => {
    const artistsQueryParams = {
      q: url,
      service: 'spotify__deezer',
    };

    const response = await miscService.smartlinkResolver(artistsQueryParams);

    if ( response.error ) {
      throw new Error(response.error.status);
    }

    return processResolverResponse(response);
  }

  querySpotify = async (searchTerm) => {
    const artistsQueryParams = {
      q: searchTerm,
      limit: 5,
    };

    const response = await miscService.spotifyArtistSearch(artistsQueryParams);

    if ( response.error ) {
      throw new Error(response.error.status);
    }

    return processSpotifyArtistsResponse(response);
  }

  submit = async (e) => {
    const { intl } = this.props;

    this.clearListedArtists();

    let parsedArtists = {};

    try {
      parsedArtists = (Validators.artistLink(this.state.link) ?
        await this.queryTrackFinder(this.state.link) : await this.querySpotify(this.state.link));
    }
    catch (err) {
      // only show error if the queried search term wasn't changed
      if (parsedArtists.q !== this.state.link) {
        return;
      }

      const errStatus = Number(err.message);
      if ( errStatus === 404 ) {
        this.setState({ linkError: intl.messages.SMARTLINK_RESOLVER_404, loading: false });
        return;
      } else if ( errStatus >= 400 ) {
        this.setState({ linkError: intl.messages.ERROR_SERVER, loading: false });
        return;
      } else if ( errStatus >= 500 ) {
        this.setState({ linkError: intl.messages.ERROR_UNKNOWN, loading: false });
        return;
      }
    }

    // only update the results list if the queried search term wasn't changed
    if (parsedArtists.q === this.state.link) {
      this.setState({loading: false});
      this.props.setListedArtists(parsedArtists.items);
    }
  };

  clearListedArtists = () => {
    this.props.clearListedArtists();
  };

  constructor(props) {
    super(props);

    this.validateArtistSearchTermDebounced = _.debounce(this.validateArtistSearchTerm, 500);
  }

  onSelectArtistWrapper = (x) => () => this.props.selectArtist(x);

  render() {
    const { toggleManual, error, intl, listedArtists, selectedArtist, disabled } = this.props;
    const { link, linkError, loading } = this.state;
    return (
      <>
        <Title>{intl.formatMessage({ id: "ONBOARDING_QUICK_TITLE" })}</Title>

        <Subtitle>
          {intl.formatMessage({ id: "ONBOARDING_QUICK_SUBTITLE" })}
        </Subtitle>

        {error && <Error>{error.message}</Error>}

        <Form id="onboarding-quick-container" loading={loading}>
          <FormGroup>
            <FormInput
              error={link.length > 0 && linkError}
              name="artist-link"
              placeholder={intl.messages.ARTIST_PLACEHOLDER}
              value={link}
              onChange={this.searchTermChange}
              loading={loading}
            />
          </FormGroup>

          {listedArtists && listedArtists.length ? (
            <ListedArtistsContainer id="onboarding-quick-search-result">
              {listedArtists.map(x => (
                <ListedArtist key={x.key} image={x.profileImage} spotifyUrl={x.spotifyArtistPage}>
                  <span>
                    {x.name}

                    {x.spotifyArtistPage && (
                    <a href={x.spotifyArtistPage} target="_blank" rel="noopener noreferrer" />
                    )}
                  </span>

                  {disabled && selectedArtist && selectedArtist.key === x.key ? (
                    <MetroSpinner size={20} color="#9B62FF"/>
                  ) : (
                    <button type="button" disabled={disabled} onClick={this.onSelectArtistWrapper(x)}>{intl.formatMessage({ id: "SELECT" })}</button>
                  )}
                </ListedArtist>
              ))}
            </ListedArtistsContainer>
          ) : (<></>)}

          {listedArtists && listedArtists.length === 0 ? (
            <ListedArtistsContainer>
              <ListedArtistsMessage>{intl.formatMessage({ id: "SMARTLINK_RESOLVER_404" })}</ListedArtistsMessage>
            </ListedArtistsContainer>
          ) : (<></>)}

          <Separator text={intl.messages.OR_SEPARATOR} />

          <FlexCenterContainer>
            <Button variant="transparent" type="button" disabled={disabled} onClick={toggleManual} id="onboarding-toggle-manual" style={{textTransform: 'none', fontFamily: 'inherit'}}>
              {intl.formatMessage({ id: "ONBOARDING_MANUAL_LABEL" })}
            </Button>
          </FlexCenterContainer>
        </Form>
      </>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(QuickFormArtist));
