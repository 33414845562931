import React from 'react';
import { Provider } from 'react-redux';
import { store } from './redux/store';

import Boot from './redux/boot';
import Main from './components/Main';
import 'reset-css';
import 'perfect-scrollbar/css/perfect-scrollbar.css'

import '@featurefm/common/assets/fonts/museo/stylesheet.css';
import './style.css'

// const currentAppLocale =
//   AppLocale[getCurrentLanguage(config.defaultLanguage || 'english').locale];

/**
 * The layout boilerplate should come here :)
 */
function App() {
  return (
    <Provider store={store}>
      <Main/>
    </Provider>
  );
}

Boot().catch(error => console.error(error));

export default App;
