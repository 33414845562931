import styled from 'styled-components';

export const CompaniesRollWrapper = styled.section`
  width: 100%;
  padding-top: 50px;
  padding-bottom: 64px;

  .slick-slider,
  .slick-slide {
    height: 32px;
  }
`;

export const CompanyItem = styled.div`
  height: 32px;
  width: 128px;
  flex-shrink: 0;
  margin: 0px 40px;

  ${props =>
    props.image &&
    `
    background-image: url( ${props.image} );
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  `}
`;
