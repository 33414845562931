import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'

import ForgotModal from './components/ForgotModal';

import { LoginContainer } from '../Login/styled';

/**
 * Okay, so I'll build this component here
 * from scratch and then move some components
 * to `common` package when I'm done :)
 */
class Login extends Component {

  render() {
    const { mobile } = this.props;

    return (<>
      { !mobile && (
        <LoginContainer>
          <PerfectScrollbar>
            <ForgotModal />
          </PerfectScrollbar>
        </LoginContainer>
      ) }

      { mobile && <ForgotModal />}
    </>)
  }
}

const mapStateToProps = (state) => ({
  mobile: state.App.mobile
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
