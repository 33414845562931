import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { FFM_CONSOLE_API_URL } from "@featurefm/common/config/env";
import LoginModal from './components/LoginModal';

import { LoginContainer } from './styled';
import qs from 'qs';

const getDestFromQueryString = (rawQueryString) => {
  const qsParams = qs.parse(rawQueryString, { ignoreQueryPrefix: true })
  if (qsParams.sc_connect == "true" || qsParams.sc_connect == true) {
    return `${FFM_CONSOLE_API_URL}/sclogin?sc_connect=true&state=${encodeURIComponent(JSON.stringify({ logoffOnError: true }))}`
  }
  return (qsParams.dest || '');
}

class Login extends Component {
  state = {
    dest: getDestFromQueryString(this.props.location.search),
  };

  render() {
    const { mobile, fixedEmail, routePrefix, targetEmail, forceEmail, emailAccountOnly } = this.props;
    const { dest } = this.state;

    const effectiveFixedEmail = fixedEmail || (forceEmail ? targetEmail : null);

    return (<>
      { !mobile && (
        <LoginContainer id="login-container">
          <PerfectScrollbar>
            <LoginModal
                dest={dest}
                fixedEmail={effectiveFixedEmail}
                routePrefix={routePrefix}
                queryString={this.props.location.search}

                emailAccountOnly={emailAccountOnly}
            />
          </PerfectScrollbar>
        </LoginContainer>
      ) }

      { mobile && <LoginModal
          dest={dest}
          fixedEmail={effectiveFixedEmail}
          routePrefix={routePrefix}
          queryString={this.props.location.search}

          emailAccountOnly={emailAccountOnly}
      />}
    </>)
  }
}

const mapStateToProps = (state) => ({
  mobile: state.App.mobile,
  targetEmail: state.Auth.targetEmail,
  forceEmail: state.Auth.forceEmail,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
