import React, { Component } from "react";
import { connect } from "react-redux";
import update from "immutability-helper";
import _ from "lodash";
import { injectIntl } from "react-intl";
import { MetroSpinner } from "react-spinners-kit";
import { withRouter } from 'react-router-dom';
import analytics from "@featurefm/common/helpers/analytics";
import * as GTM from "../../../utils/gtm";
import {
  FFM_CONSOLE_NEXT_WEB_URL,
  FFM_CONSOLE_NEXT_REDIRECT_PATH,
} from "@featurefm/common/config/env";
import {
  validateField,
  submitReady,
  formChange,
} from "@featurefm/common/helpers/form";
import MiscService from "@featurefm/common/services/misc";

import { logout } from "../../../redux/auth/actions";

import { HeaderContainer } from "../../../components/common/Header/styled";
import { Container } from "../../../components/Main.styled";

import ManualForm from "../components/ManualFormLabel";

import { Main, HeaderButton } from "../styled";
import { LoadingContainer } from "../../Login/styled";
import { ARTIST_FIELDS, getClientCountryCurrency, getCurrencyFromQueryString } from "@featurefm/common/helpers/onboarding";
import { ERROR_MESSAGES, url as isUrl } from "@featurefm/common/validators";
import mixpanel from "../../../utils/mixpanel";
import { getUserAccountName } from "@featurefm/common/helpers/mixpanel";
import { handleRedirectToFfm } from "../../../redux/app/actions";

const miscService = new MiscService();

class OnboardingLabel extends Component {
  state = {
    form: null,
    submitLoading: false,
    error: null,
  };

  presetLocation = async () => {
    const { location, currency } = await getClientCountryCurrency();
    const qsCurrency = getCurrencyFromQueryString(this.props.location.search);

    this.locationChange({ value: location });
    this.currencyChange({ value: qsCurrency || currency });
  };

  locationChange = (e) => {
    this.setState({
      form: update(this.state.form, { $merge: { location: e.value } }),
    });
  };

  currencyChange = (e) => {
    this.setState({
      form: update(this.state.form, { $merge: { currency: e.value } }),
    });
  };

  accountTypeChange = (e) => {
    this.setState({
      form: update(this.state.form, { $merge: { role: e.value } }),
    });
  };

  setPicture = (profileImage) => {
    this.setState({
      form: update(this.state.form, { $merge: { profileImage } }),
    });
  };

  clearPicture = () => {
    this.setState({
      form: update(this.state.form, { $merge: { profileImage: "" } }),
    });
  };

  submit = async (e) => {
    const { user } = this.props;

    if ( this.validateRequired() ) {
      const completesignup = _.pick( this.state.form, ["role",
        "currency",
        "location",
        "website",
        "facebook",
        "twitter",
        "youtube",
        "spotifyArtistPage",
        "soundCloudArtistPage",
        "tiktokArtistPage",
        "instagram",
        "profileImage"]);

      this.setState({ submitLoading: true });

      GTM.track("Signup", "Save Clicked");

      try {
        const completesignupRes = await miscService.completesignup(completesignup);

        //  This is kinda bad, but services don't throw errors, they return.
        //  Should think about this and redo services
        if ( completesignupRes.error ) {
          throw completesignupRes.error;
        }

        if (user) {
          mixpanel.identify(user.id);

          mixpanel.registerSuperProperties({
            '$email': user.email,
            '$name': getUserAccountName(user),
            'Plan Name': user.plan,
            'Account Name': getUserAccountName(user),
          });

          void mixpanel.refreshUserProfileProperties();
        }

        await analytics.trackEvent(
            "Label Sign Up",
            "Completed",
            analytics.getPlanLabel(),
        )

        if (this.props.invitationHash) {
          // onboarding has finished successfully,
          // so refreshing the login URL will process the accept-invitation flow
          // we do **not** go through handleRedirectToFfm saga here, as we don't want to impersonate yet,
          // only refresh the page while retaining the query params
          window.location.href = `/manage-delegate-from-invitation/login?hash=${encodeURIComponent(this.props.invitationHash)}${this.props.dest ? `&dest=${encodeURIComponent(this.props.dest)}` : ''}${this.props.impersonate ? `&impersonate=${encodeURIComponent(this.props.impersonate)}` : ''}`;
        }
        else {
          this.props.handleRedirectToFfm(this.props.dest || (FFM_CONSOLE_NEXT_WEB_URL + FFM_CONSOLE_NEXT_REDIRECT_PATH));
        }
      } catch (error) {
        this.setState({
          error,
          submitLoading: false,
        });
      }
    }
  };

  /**
   * Though Manual form will validate values,
   * required fields should be dealt with manually
   */
  validateRequired() {
    const { form } = this.state;

    const errors = {};

    if ( !form.location ) {
      errors.location = ERROR_MESSAGES.required;
    }

    if ( !form.role ) {
      errors.name = [ ERROR_MESSAGES.required, ERROR_MESSAGES.accountType ];
    }

    if ( !_.isEmpty( errors ) ) {
      this.setState( {
        form: update(form, { errors: { $merge: errors } }),
      } );

      return false;
    }

    return true;
  }

  /**
   * Log out user on onboarding cancel
   */
  cancel = (e) => {
    const { logout } = this.props;
    GTM.track("Signup", "Cancelled");

    logout();
  };

  manualFormReady() {
    const { form } = this.state;
    //  No errors, required fields are not empty
    return (
      _.isEmpty(form.errors) && form.role && form.location.length > 0
    );
  }

  checkUserForProfilePicture() {
    const { user } = this.props;

    if ( user && user.profileImage && isUrl( user.profileImage ) ) {
      this.setState({
        form: update(this.state.form, { $merge: { profileImage: user.profileImage } }),
      });
    }
  }

  getClearForm() {
    return {
      ...ARTIST_FIELDS,
      errors: {}
    };
  }

  constructor(props) {
    super(props);

    this.state.form = this.getClearForm();

    this.formFields = Object.keys(ARTIST_FIELDS);
    this.formKey = "form";

    this.validateFieldDebounced = _.debounce(validateField.bind(this), 500);
    this.submitReady = submitReady.bind(this);
    this.formChange = formChange.bind(this);
  }

  componentDidMount() {
    this.presetLocation();
    this.checkUserForProfilePicture();
  }

  componentDidUpdate( prevProps ) {
    const { user } = this.props;
    if ( !_.isEqual( prevProps.user, user ) ) {
      this.checkUserForProfilePicture();
    }
  }

  render() {
    const { intl } = this.props;
    const { form, error, submitLoading } = this.state;
    return (
      <>
        <HeaderContainer>
          <Container row style={{ height: "100%" }}>
              <HeaderButton
                id="onboarding-submit-button"
                variant="primary-inverted"
                disabled={submitLoading || !this.manualFormReady()}
                onClick={ this.submit }
              >
                { submitLoading && <LoadingContainer id="onboarding-submit-loading"><MetroSpinner size={20} color="#686769"/></LoadingContainer>}
                { !submitLoading && intl.formatMessage({ id: "ONBOARDING_SAVE" })}
              </HeaderButton>

          </Container>
        </HeaderContainer>

        <Main id="onboarding-label-container">
          <Container>
              <ManualForm
                error={error}
                review={false}
                formChange={this.formChange}
                setPicture={this.setPicture}
                clearPicture={this.clearPicture}
                locationChange={this.locationChange}
                currencyChange={this.currencyChange}
                accountTypeChange={this.accountTypeChange}
                form={form}
              />
          </Container>
        </Main>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.Auth.user,
  invitationHash: state.AcceptInvitation.originalHash,
  dest: state.App.dest,
  impersonate: state.App.impersonate,
});

const mapDispatchToProps = {
  handleRedirectToFfm,
  logout,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(withRouter(OnboardingLabel)));
