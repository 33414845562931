import React from 'react'
import styled from 'styled-components';

import '../../assets/fonts/icomoon/style.css'

export default function FFMIcon({icon,style,className}) {
  return (
    <i className={ `icomoon icomoon-${ icon } ${className}` } style={ style } />
  )
}
