import React from "react";
import { useIntl } from "react-intl";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { MetroSpinner } from "react-spinners-kit";

import { Container, InputLabel, InputGuidelines, ClearButton } from "./styled";

const LABEL = `Upload Image`;
const ACTIVE_LABEL = `Drop file here`;
const GUIDELINES = `640x640 pixels\nJPG or PNG`;

export default function ImageDropzone(props) {
  const intl = useIntl();
  const {
    width = 232,
    label,
    activeLabel,
    guidelines,
    onDrop,
    loading,
    image,
    error,
    onClear,
  } = props;
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noKeyboard: true,
    accept: ["image/png", "image/jpeg"],
    onDrop,
  });

  const clear = (e) => {
    e.stopPropagation();

    onClear();
  };

  return (
    <Container
      w={width}
      isDragActive={isDragActive}
      hasImage={!!image}
      loading={loading}
      {...getRootProps({ className: "dropzone" })}
    >
      <input {...getInputProps()} />
      {/* Idle State */}
      {!loading && (
        <div className="content">
          <FontAwesomeIcon icon={faArrowAltCircleUp} />
          {!isDragActive && (
            <InputLabel>
              {label || intl.messages.IMAGE_DROPZONE_DEFAULT_LABEL}
            </InputLabel>
          )}
          {isDragActive && (
            <InputLabel>
              {activeLabel || intl.messages.IMAGE_DROPZONE_DEFAULT_ACTIVE_LABEL}
            </InputLabel>
          )}
        </div>
      )}

      {/* Loading state */}
      {loading && (
        <div className="content">
          <MetroSpinner size={46} />
        </div>
      )}

      {/* Image State */}
      {!loading && image && (
        <>
          <div className="content image">
            <img src={image} />
          </div>

          {typeof onClear === "function" && (
            <ClearButton type="button" onClick={clear}>
              or remove current picture
            </ClearButton>
          )}
        </>
      )}

      {!error && (
        <InputGuidelines>
          {guidelines || intl.messages.IMAGE_DROPZONE_DEFAULT_GUIDELINE}
        </InputGuidelines>
      )}

      {error && <InputGuidelines color="danger">{error}</InputGuidelines>}
    </Container>
  );
}
