import styled from 'styled-components';

import { HeroSectionWrapper as W } from '../HeroSection/heroSection.style';

export const Wrapper = styled(W)`
  width: 100vw;
  height: 100vh;

  margin: 0px;
  padding: 0px;

  .react-parallax,
  .react-parallax-content {
    width: 100%;
    height: 100%;
  }
`;

export const ErrorWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ErrorTitle = styled.p`
  font-size: 52px;
  font-weight: 700;
  color: #fff;
`;

export const ErrorBody = styled.p`
  font-size: 20px;
  font-weight: 300;
  color: #fff;
`;
