import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import Login from '../Login';

class LoginAcceptInvite extends Component {
  state = {
  };

  render() {
    return (
      <Login
        location={this.props.location}
        fixedEmail={this.props.targetEmail}
        routePrefix="/manage-delegate-from-invitation"
        emailAccountOnly
      />
    )
  }
}

const mapStateToProps = (state) => ({
  targetEmail: state.AcceptInvitation.email,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LoginAcceptInvite)
