import actions from './actions';

const initialState = {
  authorized: false,
  user: null,
  targetEmail: null,
  forceEmail: false,
  error: null,
  userError: null,

  loading: true,
  userLoading: false,

  registerSuccess: false,
  resetSuccess: false,

  basicUserData: null
}

export default (state = initialState, action) => {
  switch (action.type) {

  case actions.SET_USER:
    return {
      ...state,
      user: action.user,
      userError: null
    }

  case actions.SET_AUTH_LOADING:
    return {
      ...state,
      error: null,
      loading: true
    }

  case actions.SET_AUTH_LOADED:
    return {
      ...state,
      loading: false
    }

  case actions.SET_AUTH_ERROR:
    return {
      ...state,
      error: action.error,
      errorId: action.errorId,
      forceEmail: state.forceEmail || action.forceEmail,  // retain forceEmail if already set
      targetEmail: state.forceEmail ? state.targetEmail : action.targetEmail,  // retain targetEmail if forceEmail specified
      loading: false,
      aggregatedErrors: action.error ? (state.aggregatedErrors || []).concat(
          // `Email: ${action.targetEmail || 'unknown'}, Auth Error: ${(action.error || {}).message}`
          `Authentication Error` // only aggregate the "field" / main cause for error
      ) : state.aggregatedErrors,
    }

  case actions.AGGREGATE_ERROR:
    return {
      ...state,
      aggregatedErrors: action.error ? (state.aggregatedErrors || []).concat(
          // `Field: ${action.name}, Value: ${action.value}, Error: ${action.error}`
          action.name // only aggregate the field name
      ) : state.aggregatedErrors,
    }

  case actions.SET_USER_ERROR:
    return {
      ...state,
      userError: action.error,
      userLoading: false,
    }

  case actions.REGISTER_SUCCESS:
    return {
      ...state,
      registerSuccess: true
    }

  case actions.RESET_SUCCESS:
    return {
      ...state,
      resetSuccess: true
    }

  case actions.AUTH_SUCCESS:
    return {
      ...state,
      authorized: true
    }

  case actions.AUTH_RESET:
    return {
      ...state,
      authorized: false,
      user: null,
      userError: null,
    }

  case actions.RESET_SUCCESS_STATE:
    return {
      ...state,
      resetSuccess: false,
      registerSuccess: false
    }

  case actions.SET_BASIC_USER_DATA:
    return {
      ...state,
      basicUserData: action.user
    }

  default:
    return state
  }
}
