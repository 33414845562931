import React, { Component } from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import _ from "lodash";
import { ScaleLoader } from 'react-spinners';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { injectIntl } from 'react-intl'

import { resetPassword } from "../../../redux/auth/actions";

import Logo from "@featurefm/common/components/Logo";
import FormInput from "@featurefm/common/components/FormInput";
import {
  validateField,
  submitReady,
  formChange
} from "@featurefm/common/helpers/form";

import {
  LoginModal as Modal,
  Form,
  Button,
  LoginTitle,
  Subtext,
  Error,
  RoleContainer,
  SuccessIcon
} from "../../Login/styled";
import { FFM_LANDING_PAGE_URL } from "@featurefm/common/config/env";
import { LOGIN_LG_SCALE } from "@featurefm/common/config";


class ForgotModal extends Component {
  state = {
    email: "",
    errors: {}
  };

  resetPassword = e => {
    const { resetPassword } = this.props;
    const { email } = this.state;

    resetPassword( email );
  };

  toLogin = e => {
    const { push } = this.props;
    push("/login");
  };

  inputKeyUp = e => {
    e.preventDefault();
    if ( e.key === "Enter" ) {
      this.resetPassword();
    }
  }

  constructor(props) {
    super(props);

    this.formFields = ["email"];

    this.validateFieldDebounced = _.debounce(validateField.bind(this), 500);
    this.submitReady = submitReady.bind(this);
    this.formChange = formChange.bind(this);
  }

  render() {
    const { mobile, error, loading, resetSuccess, intl } = this.props;
    const { email, errors } = this.state;
    return (
      <>
      { resetSuccess &&
        <RoleContainer>
          <Logo />

          <SuccessIcon style={{marginTop: 128}}>
            <FontAwesomeIcon icon={ faCheck } />
          </SuccessIcon>

          <LoginTitle style={{ margin: '1em 0px'}}>
            { intl.formatMessage( { id: 'SUCCESS_TITLE' } ) }
          </LoginTitle>
          <Subtext>
            { intl.formatMessage( { id:'RESET_SUCCESS'}, { email: (<strong>{ email }</strong>) } )}
          </Subtext>

          <Button
           as="a"
           href={ FFM_LANDING_PAGE_URL }
           style={{
            width: '15vw',
            minWidth: 230,
            marginTop: '2em'
           }}
          >
            { intl.formatMessage( { id: 'FORGOT_BACK_LANDING' } ) }
          </Button>
        </RoleContainer>
      }
      { !resetSuccess &&
        <Modal style={{ margin: 'auto 0px', paddingBottom: mobile ? 80 : 80 * LOGIN_LG_SCALE }}>
          <Logo />

          <LoginTitle style={{ marginBottom: '1em' }}>{ intl.formatMessage( { id: 'RESET_TITLE' } ) }</LoginTitle>

          <Subtext>
            { intl.formatMessage( { id: 'RESET_SUBTITLE' } ) }
          </Subtext>

          { error && <Error style={{ marginTop: '1.25em', marginBottom: '-1em'}}>{error.message}</Error> }

          <Form loading={ loading } style={{ margin: '30px auto' }}>
            <FormInput
              error={email.length > 0 && errors.email}
              type="email"
              placeholder={ intl.messages.EMAIL_PLACEHOLDER }
              name="email"
              value={email}
              onChange={this.formChange}
              onKeyUp={this.inputKeyUp}
            />
          </Form>

          <Button
            variant="accent"
            onClick={this.resetPassword}
            disabled={this.submitReady()}
            loading={ loading }
            style={{marginBottom: '1em'}}
          >
            { !loading && intl.formatMessage( { id: 'CONTINUE' } ) }
            { loading && <ScaleLoader color={'#fff'} /> }
          </Button>
          <Button onClick={this.toLogin}>{ intl.formatMessage( { id: 'FORGOT_BACK' } ) }</Button>
        </Modal>
      }
    </>);
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
  loading: state.Auth.loading,
  error: state.Auth.error,
  resetSuccess: state.Auth.resetSuccess
});

const mapDispatchToProps = {
  resetPassword,
  push
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ForgotModal));
