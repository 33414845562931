import styled, { css } from 'styled-components';
import Color from 'color';

import { Modal, FlexCenterContainer } from '@featurefm/common/components/styled';
import { flex, flexCol, flexCenter, breakpointMax, buttonReset } from '@featurefm/common/components/styled/mixins';
import { InputGroup, Input, InputContainer, InputError, FormGroup } from '@featurefm/common/components/FormInput/styled';
import { COLORS, SCREENS, LOGIN_LG_SCALE } from '@featurefm/common/config';

import { SwitchContainer } from '@featurefm/common/components/CircleSwitch/styled'

function pxscale( value ) {
  return `${ Math.floor( value * LOGIN_LG_SCALE ) }px`;
}

export const LoginContainer = styled.section`
  background: ${ props => props.theme.bgDefault };
  overflow: hidden;
  position: relative;

  ${ SwitchContainer } {
    position: absolute;

    top: 16px;
    left: 32px;
    margin: 0px;
  }

  .ps.scrollbar-container {
    width: 100%;
    height: 100vh;
    overflow: hidden;

    ${ flex }
    ${ flexCol }

    align-items: center;

    z-index: 0;
  }

  ${ Input } {
    font-size: ${ pxscale( 16 ) };
    padding: 0px ${ pxscale( 18 ) };
    border-radius: ${ pxscale( 6 ) };
  }

  ${ InputGroup } {
    min-height: ${ pxscale(60) };
  }

  ${ InputContainer } {
    height: ${ pxscale(60) };
    border-radius: ${ pxscale(8) };

    padding: ${ pxscale( 2 ) };
  }

  ${ InputError } {
    font-size: ${ pxscale(12) };
    margin-top: ${ pxscale(7) };
  }

  ${ FormGroup } ${ InputGroup } {
    max-width: calc( 50% - ${ pxscale(8) } );
  }

  @media (min-height: ${ props => props.justifyBreakpoint || '720px' }) {
    .ps.scrollbar-container {
      justify-content: center;
    }
  }
`;

const mobileFullwidth = css`
  ${ breakpointMax( 'lg', `
    width: 100vw;
    min-height: 100vh;
    box-shadow: none;
    margin: 0px;
  ` ) }

  ${ breakpointMax( 'md', `
    padding: 22px 28px;
  ` ) }
`;

export const LoginModal = styled(Modal)`
  padding: ${ pxscale(58) } ${ pxscale(80) } 0px;
  margin: ${ pxscale(24) } 0px;

  width: ${ pxscale(520) };
  height: auto;

  ${ mobileFullwidth }
`

export const AcceptInviteModal = styled(Modal)`
  width: 100vw;
  height: auto;

  ${ mobileFullwidth }
`

//  Should be common
export const Form = styled.form`
  ${ InputGroup }:not(:first-child) {
    margin-top: ${ pxscale(16) };
  }

  width: 100%;
  margin-bottom: ${ pxscale(45) };

  ${ props => props.loading && `
    pointer-events: none;
    opacity: 0.65;
  ` }

  ${ breakpointMax( 'md', `
    margin: 32px auto 12px;
  ` ) }
`;

//  Should be common
const buttonTypes = {
  accent: css`
    background: ${ props => props.theme.name === 'ada' ? COLORS.ada : COLORS.purple };

    :hover {
      background: ${ props => props.theme.name === 'ada' ? Color(COLORS.ada).lighten(0.20).hex() : COLORS.purple };
    }
  `,
  facebook: css`
    background: ${ COLORS.facebook };

    :hover {
      background: ${ Color( COLORS.facebook ).lighten(0.20).hex() };
    }
  `,
  soundcloud: css`
    background: ${ COLORS.soundcloud };
    margin-top: 20px;

    :hover {
      background: ${ Color( COLORS.soundcloud ).lighten(0.20).hex() };
    }
  `,
  primary: css`
    background: ${ props => props.theme.primary };

    :hover {
      background: ${ props => Color( props.theme.primary ).lighten(0.20).hex() };
    }
  `,
  danger: css`
    background: ${ props => props.theme.danger };

    :hover {
      background: ${ props => Color( props.theme.danger ).lighten(0.20).hex() };
    }
  `,
  'primary-inverted': css`
    background: ${ props => props.theme.bgSecondary };
    color: ${ props => props.disabled ? Color( props.theme.primary ).alpha(0.25).rgb().string() : props.theme.primary };

    :hover:enabled {
      background: ${ props => props.theme.primary };
      color: ${ props => props.theme.bgSecondary };
    }
  `,
  transparent: css`
    background: transparent;
    color: ${ props => props.theme.primary };

    :hover {
      opacity: 0.8;
    }

    :disabled {
      opacity: 0.25;
    }
  `
}

export const LoadingContainer = styled.span`
  position: absolute;

  ${ flex }
  ${ flexCenter }

  width: 100%;
  height: 100%;

  top: 0px;
  left: 0px;
`;


//  Should be common
export const Button = styled.button`
  outline: none;
  padding: 0px;

  body.ffm-app & {
    font-family: 'Montserrat', sans-serif;
  }

  ${ flex }
  ${ flexCenter }

  position: relative;

  width: 100%;
  height: ${ pxscale(64) };


  font-weight: bold;
  font-size: ${ props => props.fontSize || `${ pxscale(16) }` };

  text-transform: uppercase;

  ${ props => props.text && COLORS[ props.text ] ? `
    color: ${ props.disabled ? Color( COLORS[ props.text ] ).alpha(0.25).rgb().string() : COLORS[ props.text ] } !important;
  ` : `
    color: ${ props.disabled ? `rgba( 255,255,255,0.25 )` : `#fff` };
  ` }

  ${ props => props.variant ? buttonTypes[ props.variant ] : `
    background: ${ Color( props.theme.textMain ).alpha(0.1).rgb().string() };

    &:hover {
      background: ${ Color( props.theme.textMain ).alpha(0.2).rgb().string() };
    }
  ` }

  ${ props => ( props.loading || props.disabled ) && `
    pointer-events: none;
  ` }

  ${ props => props.loading && `
    opacity: 0.75;
  ` }



  border-width: 0px;
  border-radius: 9999px;

  .fa-facebook-f,
  .fa-soundcloud {
    position: absolute;
    left: ${ pxscale(30) };
    width: ${ pxscale(12) };
    height: ${ pxscale(24) };
  }

  ${ breakpointMax( 'md', `
    height: 54px;
    font-size: 14px;

    .fa-facebook-f,
    .fa-soundcloud {
      position: relative;
      left: 0px;
      margin-right: 12px
    }

    & + & {
      margin-top: 14px;
    }
  `)}
`;

export const ConvertButton = styled(Button)`
  min-width: ${ pxscale(520) };
  width: auto;
  padding: 0 15px;

  ${ breakpointMax( 'md', `
    min-width: unset;
    max-width: calc( 100% - 8px );
  ` )}
`;

//  Very specific styles
export const Subtext = styled.div`
  color: ${ props => Color(props.theme.textMain).alpha(0.3).rgb().string() };
  text-align: center;
  font-weight: 500;
  font-size: ${ pxscale(12) };
  line-height: ${ pxscale(15) };

  margin-top: ${ pxscale(12) };

  white-space: pre-line;

  a {
    color: ${ props => Color(props.theme.accent).alpha(0.8).rgb().string() };
  }

  a:hover {
    color: ${ props => props.theme.accent };
  }

  ${ breakpointMax( 'md', `
    font-size: 10px;
  ` )}
`;

export const LoginTitle = styled.div`
  color: ${ props => props.theme.textMain };

  font-weight: bold;
  font-size: ${ pxscale(24) };
  line-height: ${ pxscale(30) };
  text-align: center;

  margin: 1.35em 0px;

  & + ${ Subtext } {
    margin-top: 0px;
  }
`;

export const LoginDescription = styled.div`
  color: ${ props => props.theme.textMain };

  font-size: ${ pxscale(16) };
  line-height: ${ pxscale(20) };
  text-align: center;

  & + ${ Subtext } {
    margin-top: 0px;
  }
  
  margin-bottom: 1.35em;
`;

export const ConvertRoleDescription = styled.div`
  color: ${ props => Color(props.theme.textMain).alpha(0.7).rgb().string() };
  text-align: center;
  font-weight: 500;
  font-size: ${ pxscale(16) };
  line-height: ${ pxscale(24) };
  max-width: calc( 50% - 8px );

  margin-top: ${ pxscale(12) };
  margin-bottom: ${ pxscale(36) };

  white-space: pre-line;
  
  b {
    font-weight: bold;
  }
  
  a {
    font-weight: bold;
    color: ${ props => Color(props.theme.textMain).alpha(0.8).rgb().string() };
  }

  ${ breakpointMax( 'md', `
    font-size: 10px;
    max-width: calc( 100% - 8px );
  ` )}
`;

export const Separator = styled.div`
  ${ flex }
  ${ flexCenter }

  width: 100%;
  height: ${ pxscale(38) };
  position: relative;

  text-transform: uppercase;

  :before,
  :after {
    display: block;
  }

  :before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 2px;

    left: 0px;
    top: 50%;
    margin-top: -1px;
    background: linear-gradient( 270deg, rgba(255,255,255,0) 0%, ${ props => props.theme.mutedText } 50%, rgba(255,255,255,0) 100% );
    z-index: 1;
  }

  :after {
    content: ${ props => props.text ? `"${ props.text }"` : `` };

    position: relative;
    z-index: 1;

    padding: 0px ${ pxscale(8) };
    background: ${ props => props.theme.bgSecondary };
    color: ${ props => props.theme.mutedText };
    font-weight: 500;
    font-size: ${ pxscale(12) };
    text-align: center;
  }
`;

export const ForgotPassLink = styled.div`
  ${ flex }

  justify-content: flex-end;

  font-weight: bold;
  font-size: ${ pxscale(10) };
  line-height: ${ pxscale(14) };

  margin-top: ${ pxscale(12) };

  text-transform: uppercase;

  a {
    ${ flex }
    align-items: center;
    text-decoration: none;
    color: ${ props => Color(props.theme.textMain).alpha(0.2).rgb().string() };
  }

  a:hover {
    color: ${ props => Color(props.theme.textMain).alpha(0.4).rgb().string() };

    svg path {
      fill: ${ props => Color(props.theme.textMain).alpha(0.4).rgb().string() };
    }
  }

  a svg {
    font-size: ${ pxscale(14) };
    margin-right: ${ pxscale(4) };
    width: 11px;
    height: 13px;
    max-height: 13px;
    margin-right: 3px;
    margin-top: 2px;
    path{
      fill: ${ props => Color(props.theme.textMain).alpha(0.2).rgb().string() };
    }
  }

  ${ breakpointMax( 'md', `
    justify-content: center;
  `)}
`;

export const SingupLink = styled.div`
  height: 100px;

  font-weight: 500;
  font-size: ${ pxscale(16) };
  line-height: ${ pxscale(20) };
  display: flex;
  align-items: center;
  text-align: center;

  color: ${ props => props.theme.textMain };

  a {
    font-weight: 700;
    margin-left: ${ pxscale(4) };
    color: #9B62FF;
  }

  a:hover {
    color: ${ props => Color( '#9B62FF' ).lighten(0.15).hex() }
  }

  ${ breakpointMax( 'md', `
    font-size: 14px;
    height: 94px;
  ` )}
`;

export const RoleContainer = styled( FlexCenterContainer )`
  min-height: 100vh;
  padding: ${ pxscale(92) } 0px;

  ${ mobileFullwidth }

  ${ breakpointMax( 'lg', `
    padding: 22px 28px;
  ` ) }
`

export const RoleButtonsGroup = styled.div`
  ${ flex }

  flex-grow: 1;
  margin-top: ${ pxscale(92) };

  ${ breakpointMax( 'lg', `
    width: 100%;
    margin-top: 20px;

    ${ flexCol }
  ` )}
`

export const RoleButtonLabel = styled.div`
  font-weight: 500;
  font-size: ${ pxscale(32) };
  line-height: 1em;
  text-align: center;

  color: ${ props => props.theme.logoStyle === 'dark' ? Color( props.theme.textMain ).alpha(0.4).rgb().string() : props.theme.textMain };

  @media (max-height: 680px) {
    font-size: ${ pxscale(28) };
  }

  ${ breakpointMax( 'lg', css`
    font-size: 16px;
    color: ${ props => props.theme.textMain };
    flex-grow: 1;
  `) }

  ${ breakpointMax( 'sm', `
    font-size: 14px;
  ` ) }
`;

export const RoleButton = styled.button`
  ${ flex }
  ${ flexCol }
  ${ flexCenter }
  ${ buttonReset }

  margin: 0px ${ pxscale(15) };
  background: ${ props => props.theme.bgSecondary };
  box-shadow: 0px 0px ${ pxscale(20) } rgba(0, 0, 0, 0.1);
  border-radius: ${ pxscale(10) };

  width: 28vw;
  height: 60vh;

  padding: ${ pxscale(20) } 0px;

  svg {
    width: ${ pxscale(180) };
    height: ${ pxscale(180) };

    margin-bottom: ${ pxscale(60) };
  }

  :hover {
    background: linear-gradient(230.29deg, #A445B2 0%, #D41872 51.71%, #FF0066 100%);
    box-shadow: 0px ${ pxscale(5) } ${ pxscale(13) } rgba(232, 12, 108, 0.3);

    ${ RoleButtonLabel } {
      color: ${ COLORS.white };
    }

    svg path {
      fill: ${ COLORS.white } !important
    }
  }

  @media (max-height: 680px), (max-width: ${ SCREENS.xl }px) {
    width: 30vw;
    height: 50vh;

    padding: ${ pxscale(20) } 0px;

    svg {
      width: ${ pxscale(120) };
      height: ${ pxscale(120) };

      margin-bottom: ${ pxscale(48) };
    }
  }

  ${ breakpointMax( 'lg', `
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;

    width: 100%;
    min-height: 200px;
    height: auto;

    margin: 15px 0px;
    padding: 0px 20px;

    > div:first-child {
      min-width: 50%;
    }

    svg {
      margin-bottom: 0px;

      width: 90px;
      height: 90px;
    }
  ` ) }

  ${ breakpointMax( 'sm', `
    margin: 10px 0px;
  ` ) }
`;

export const ArtistRoleButton = styled(RoleButton)`
  :hover {
    background: ${ COLORS.purple };
    box-shadow: none;
  }
`

export const RepresentsRoleButton = styled(RoleButton)`
  :hover {
    background: ${ COLORS.green };
    box-shadow: none;
  }
`

export const Error = styled.div`
  ${ flex }

  min-height: 2.5em;
  align-items: center;

  font-size: ${ pxscale(14) };

  background: ${ props => props.theme.danger };
  border: 1px solid ${ props => Color( props.theme.danger ).darken(0.3).rgb().string() };
  box-sizing: border-box;
  border-radius: ${ pxscale(6) };

  padding: 0.5em 1.2em;
  line-height: 1.2em;

  font-weight: 600;

  color: #fff;

  margin-top: -1.25em;
  margin-bottom: 1.25em;

  ${ breakpointMax( 'md', `
    font-size: 10px;
    margin-top: 2.5em;
    margin-bottom: -0.5em;
    padding: 0px 1.2em;
  ` )}
`;

export const AdaPlaceHolder = styled.div`
  height: 49px;
`

export const SuccessIcon = styled.div`
  ${ flex }
  ${ flexCenter }

  width: 90px;
  height: 90px;

  font-size: 36px;

  border-radius: 45px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.2);

  background: ${ props => props.theme.success };
  color: ${ COLORS.white };
`
export const WarningIcon = styled.div`
  ${ flex }
  ${ flexCenter }

  width: 90px;
  height: 90px;

  font-size: 36px;

  color: ${ props => props.theme.danger };
`
