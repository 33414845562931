import styled, { css } from 'styled-components';

import { CompaniesRollWrapper } from '../CompaniesRoll/companiesRoll.style';

export const CompaniesRollTitle = styled.div`
  font-size: 22px;
  color: rgba(255, 255, 255, 0.2);
  font-weight: bold;
  line-height: 1.95;
  letter-spacing: normal;
  text-align: center;

  margin-bottom: -24px;

  @media (max-width: 768px) {
    margin-top: 38px;
  }
`;

export const HeroSectionWrapper = styled.section`
  height: ${props => props.height || 'auto'};
  padding-top: 100px;
  margin-top: -100px;
  padding-bottom: 40px;

  text-align: center;

  ${props =>
    props.static &&
    `
    min-height: ${
      props.minHeight ? props.minHeight : props.showCompaniesRoll ? 970 : 860
    }px;

    ${CompaniesRollWrapper} {
      position: absolute;

      bottom: ${props.page === 'enterprise' ? 90 : 10}px;
      left: 0px;

      z-index: 21;
    }

    ${CompaniesRollTitle} {
      position: absolute;

      width: 100%;
      left: 0px;
      bottom: ${props.page === 'enterprise' ? 250 : 170}px;

      z-index: 21;
    }
  `}

  ${props =>
    props.bgColor
      ? `
    background-color: ${props.bgColor};
  `
      : `
    background: linear-gradient(90deg, #2c2c2c, #000000);
  `}

  .react-parallax-background {
    position: relative;
    top: 400px;
  }

  @media (max-width: 768px) {
    padding-top: 64px;
    margin-top: -64px;
    padding-bottom: 0px;

    ${props =>
      props.static &&
      `
      min-height: ${props.showCompaniesRoll ? 135 : 100}vh;
    `}
  }
`;

export const HeroSectionBackground = styled.section`
  position: absolute;
  z-index: 10;

  width: 100%;
  height: 100%;

  top: 0px;
  left: 0px;

  background: ${props =>
      props.bgColor && `linear-gradient( transparent 80%, ${props.bgColor} )`},
    ${props => (props.image ? `url( ${props.image} )` : 'none')},
    ${props => (props.bgColor ? props.bgColor : 'none')};

  background-position: center;
  background-size: auto 100%;
  background-repeat: no-repeat;

  ${props => props.stretch && `background-size: 100%`}
  ${props =>
    props.cover && `background-size: cover`}

  @media (max-width: 769px) {
    max-height: 100vh;
  }
`;

export const HeroTextWrapper = styled.section`
  position: relative;
  z-index: 21;
  display: flex;
  flex-direction: column;

  align-items: center;

  margin-top: 100px;

  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 768px) {
    margin-top: 40px;

    padding: 0px 32px;
  }
`;

export const HeroSectionGradient = styled(HeroSectionBackground)`
  z-index: 11;
  background-image: linear-gradient(${props => props.gradient});
  background-color: transparent;
`;

export const Title = styled.h1`
  /* text-h1 font-bold text-white text-center mb-10 */

  font-weight: 700;
  font-size: 38px;
  color: #fff;
  text-align: center;
  margin: 0px;

  br:not([mobile]) {
    display: initial;
  }
  br[mobile] {
    display: none;
  }

  .badge {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    height: 34px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;

    position: relative;
    top: -3px;

    border-radius: 17px;
    background-color: #35d36e;

    text-transform: uppercase;

    padding: 0px 16px;
  }

  @media (min-width: 768px) {
    .badge {
      top: -6px;
    }
  }

  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 32px;

    br:not([mobile]) {
      display: none;
    }
    br[mobile] {
      display: initial;
    }
  }
`;

export const Body = styled.p`
  /* text-white text-lg text-center mb-10 font-medium opacity-50 */

  font-weight: 500;
  font-size: 18px;
  color: #fff;
  text-align: center;
  opacity: 0.5;

  margin-top: 32px;
  margin-bottom: 46px;

  @media (max-width: 768px) {
    margin: 20px 10px;
    font-size: 14px;
  }
`;

export const Button = styled.button`
  /* text-lg text-white font-bold uppercase rounded-lg mx-auto h-16 px-8 bg-accent-gradient hover:bg-accent-gradient-hover */

  /* width: 248px; */

  font-weight: 700;
  border-radius: 8px;

  text-align: center;
  text-transform: uppercase;
  border: none;
  outline: none;
  padding: 0px 24px;
  cursor: pointer;

  color: ${props => props.color || `#ffffff`};
  font-size: ${props => props.fontSize || `18px`};
  height: ${props => props.height || `64px`};
`;

export const HeroButton = styled(Button)`
  justify-content: center;
  align-items: center;

  height: 64px;

  margin-left: auto;
  margin-right: auto;

  background: linear-gradient(256deg, #a445b2 0%, #d41872 50%, #ff0066 100%);
  box-shadow: 0 5px 13px 0 rgba(232, 12, 108, 0.2);

  :hover {
    background: linear-gradient(256deg, #b866c4 0%, #e8378c 50%, #ff3385 100%);
    color: #fff;
  }

  @media (min-width: 769px) {
    ${props => (props.mobile ? `display: none` : 'display: inline-flex')}
  }

  @media (max-width: 768px) {
    ${props => (!props.mobile ? `display: none` : 'display: inline-flex')}

    margin: 0 auto;
    height: 54px;
    font-size: 16px;
  }
`;

export const AnimatedWordContainer = styled.span`
  display: inline-block;
  position: relative;
  overflow: hidden;

  @media (min-width: 769px) {
    html:not(.ua-safari) & {
      top: 11px;
    }
  }

  @media (max-width: 768px) {
    top: 6px;
  }
`;

export const AnimatedWord = styled.span`
  display: inline-block;

  position: absolute;
  top: 0px;
  left: 0px;
  opacity: 0;
  transform: translateY(-1.5em);
  transition: transform .4s ease-in, opacity .4s ease-in;

  color: ${props => props.color || '#65c673'};

  ${props =>
    props.active &&
    `
    opacity: 1;
    transform: translateY(0px);
    position: relative;
  `}

  ${props =>
    props.animate &&
    css`
      position: absolute;
      opacity: 0;
      transform: translateY(1.5em);
    `}

  ${props => props.noTransition && `transition: none;`}
`;
