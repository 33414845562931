import React, { Component } from 'react'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {Button, LoginContainer} from '../Login/styled';
import {Title, SubTitle, RowGrid, Cancel, Logo, TermText, Box} from './styled';
import { LazyLoadImage } from "react-lazy-load-image-component";
import {FFM_CONSOLE_API_URL} from "@featurefm/common/config/env";

class AcceptTerms extends Component {

  onClick = () => {
    const sp = new URLSearchParams(this.props.location.search);

    if (sp.get("jwt") !== '') {
      setTimeout(() => {
        window.top.location.href = `${FFM_CONSOLE_API_URL}/jwtLogin?jwt=${sp.get("jwt")}&termsAccepted=true`
      }, 0)
    }
  }

  render() {
    return (<>
      <LoginContainer justifyBreakpoint="480px">
        <PerfectScrollbar>
          <RowGrid>
            <Box>
              <Logo />
              <Title>Welcome to Feature.fm</Title>
              <SubTitle>You're one step away from accessing your music marketing suite</SubTitle>
              <RowGrid>
                <Button
                    id="button-accept"
                    variant="accent"
                    style={{padding: '0 60px'}}
                    onClick={this.onClick}
                    loading={false}
                >
                  Accept & Continue
                </Button>
                <Cancel>
                  <a href="https://feature.fm">Cancel</a>
                </Cancel>
              </RowGrid>
              <TermText>To get started, please accept our <a href="https://ffm.to/terms" target="_blank" style={{color: '#9B62FF'}}>Terms of Use</a> and <a href="https://ffm.to/privacypolicy" target="_blank" style={{color: '#9B62FF'}}>Privacy Policy</a></TermText>
            </Box>
            <Box removeOnMobile>
              <LazyLoadImage width="100%" src="/accept-terms_main-image.png" />
            </Box>
          </RowGrid>
        </PerfectScrollbar>
      </LoginContainer>
    </>)
  }
}

const mapStateToProps = (state) => ({
  mobile: state.App.mobile
})

const mapDispatchToProps = {}

export default AcceptTerms
