import ExtendedAxios from '../helpers/axios';

import { FFM_CONSOLE_API_URL } from '../config/env';

const axios = new ExtendedAxios({baseURL: FFM_CONSOLE_API_URL});

export default class InvitationService {
  static accept( hash, bForceUpgradeFreeArtistToPromoter ) {
    return axios.post( "/accept-management-delegation-invitation"  + (bForceUpgradeFreeArtistToPromoter ? '?force-convert-promoter=true' : ''), {hash: encodeURIComponent(hash)} );
  }

  static deny(hash) {
    return axios.post( "/deny-management-delegation-invitation", {hash: encodeURIComponent(hash)} );
  }

  static send ( jwt, dest ) {
    return axios.get( `/issuer-resend-invite-email?jwt=${encodeURIComponent(jwt)}&dest=${encodeURIComponent(dest)}` );
  }
}
