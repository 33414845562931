import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { ScaleLoader } from 'react-spinners';
import { injectIntl } from 'react-intl'

import {
  LoginTitle,
  RoleContainer,
  LoadingContainer,
  ConvertRoleDescription, ConvertButton, WarningIcon, SingupLink, Separator
} from "../../Login/styled";
import {FFM_CONSOLE_NEXT_REDIRECT_PATH, FFM_CONSOLE_NEXT_WEB_URL} from "@featurefm/common/config/env";
import {acceptInvitation, denyInvitation} from "../../../redux/acceptInvitation/actions";
import { addProtocolToUrl } from "@featurefm/common/helpers/url";
import {ERROR_TYPES} from "@featurefm/common/helpers/invitation";
import {CONTACT_US_URL} from "@featurefm/common/config";

class AcceptedUserDelegationInvitation extends Component {
  state = {
    loading: false,
  };

  retry = e => {
    const { errorType } = this.props;
    this.setState({ loading: true });

    setTimeout(() => {
      if (errorType === ERROR_TYPES.GENERIC_DENY_ERROR) {
        this.props.denyInvitation();
      }
      else {
        this.props.acceptInvitation(false);
      }
    })
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { intl, errorData, errorType } = this.props;
    const { loading } = this.state;

    let errorText = intl.messages.ACCEPT_INVITATION_ERROR_SUBTITLE;

    switch (errorType) {
      case ERROR_TYPES.EMAIL_MISMATCH:
        errorText = intl.formatMessage( { id: 'ACCEPT_INVITATION_ERROR_SUBTITLE_EMAIL_MISMATCH' }, errorData );
        break;
      case ERROR_TYPES.ROLE_MISMATCH:
        errorText = intl.formatMessage( { id: 'ACCEPT_INVITATION_ERROR_SUBTITLE_ROLE_MISMATCH' }, {
          cu: (...chunks) => (<a href={ CONTACT_US_URL } target="_blank" rel="noopener noreferrer">{chunks}</a>),
        } );
        break;
      case ERROR_TYPES.GENERIC_DENY_ERROR:
        errorText = intl.messages.DENY_INVITATION_ERROR_SUBTITLE;
        break;

      case ERROR_TYPES.GENERIC_ACCEPT_ERROR:
      default:
        break;
    };

    const bAllowRetry = (
        this.props.errorType != ERROR_TYPES.EMAIL_MISMATCH &&
        this.props.errorType != ERROR_TYPES.ROLE_MISMATCH
    );

    return (<>
        <RoleContainer>
          <WarningIcon>
            <FontAwesomeIcon icon={ faExclamationTriangle } />
          </WarningIcon>

          <LoginTitle style={{ margin: '1em 0px'}}>
            { intl.messages.ACCEPT_INVITATION_ERROR_TITLE }
          </LoginTitle>
          <ConvertRoleDescription>
            { errorText }
          </ConvertRoleDescription>

          {bAllowRetry && (<ConvertButton id="button-retry" variant="accent" loading={ loading } onClick={this.retry}>
            { !loading && intl.messages.RETRY_OPERATION }
            { loading && <LoadingContainer id="button-retry-loading"><ScaleLoader color={'#fff'} /></LoadingContainer> }
          </ConvertButton>)}

          {bAllowRetry && (<Separator style={{marginTop: 15}} text="OR" />)}

          <SingupLink id="link-continue-to-site">
            <a href={addProtocolToUrl(FFM_CONSOLE_NEXT_WEB_URL + FFM_CONSOLE_NEXT_REDIRECT_PATH)}>{ intl.messages.CONTINUE_TO_SITE } →</a>
          </SingupLink>

        </RoleContainer>
      </>
    );
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
  errorType: state.AcceptInvitation.error,
  errorData: state.AcceptInvitation.errorData,
});

const mapDispatchToProps = {
  acceptInvitation,
  denyInvitation,
};

export default connect(mapStateToProps, mapDispatchToProps)( injectIntl(AcceptedUserDelegationInvitation) );
