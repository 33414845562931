import React, { Component } from "react";
import { connect } from "react-redux";
import qs from "qs";
import { SOUNDCLOUD_ENABLED } from '@featurefm/common/config/env';

import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faSoundcloud } from "@fortawesome/free-brands-svg-icons";
import { ScaleLoader } from "react-spinners";
import { FormattedMessage, injectIntl } from "react-intl";
import InlineSVG from 'svg-inline-react';
import { login, facebookLogin, soundCloudLogin } from "../../../redux/auth/actions";

import Logo from "@featurefm/common/components/Logo";
import Icon from "@featurefm/common/components/Icon";
import FormInput from "@featurefm/common/components/FormInput";
import { submitReady, formChange } from "@featurefm/common/helpers/form";

import {
  LoginModal as Modal,
  Form,
  LoginDescription,
  AdaPlaceHolder,
  Button,
  LoginTitle,
  Separator,
  Subtext,
  SingupLink,
  ForgotPassLink,
  Error,
  LoadingContainer
} from "../styled";


class LoginModal extends Component {
  state = {
    email: this.props.fixedEmail || this.props.targetEmail || "",
    password: "",
  };

  login = (e) => {
    const { login, dest } = this.props;
    const { email, password } = this.state;

    login(email, password, dest);
  };

  facebookLogin = (e) => {
    const { facebookLogin, location, dest } = this.props;

    const forceStubs = location.query[ 'force-stubs' ] === "true" || location.query[ 'force-stubs' ] === true;
    facebookLogin({ role: 'artist', forceStubs, dest });
  };

  soundCloudLogin = (e) => {
    const { soundCloudLogin, location, dest } = this.props;

    const forceStubs = location.query[ 'force-stubs' ] === "true" || location.query[ 'force-stubs' ] === true;
    soundCloudLogin({ role: 'artist', forceStubs, dest });
  };

  inputKeyUp = e => {
    if ( e.key === "Enter" ) {
      this.login();
    }
  }

  constructor(props) {
    super(props);

    this.formFields = ["email", "password"];

    this.submitReady = submitReady.bind(this);
    this.formChange = formChange.bind(this);
  }

  render() {
    const { mobile, loading, theme, error, intl, fixedEmail, emailAccountOnly, routePrefix, queryString } = this.props;
    const { email, password } = this.state;

    const isSoundCloudVerification = (qs.parse(queryString, { ignoreQueryPrefix: true }) || {}).sc_connect == "true"

    return (
      <Modal id="login-modal">
        <Logo />

        {!mobile && (
          <LoginTitle>
            <FormattedMessage id={isSoundCloudVerification ? "SOUNDCLOUD_LOGIN_TITLE" : "LOGIN_TITLE"} />
          </LoginTitle>
        )}

        {!mobile && isSoundCloudVerification && (
            <LoginDescription>
              <FormattedMessage id={"SOUNDCLOUD_LOGIN_DESCRIPTION"} />
            </LoginDescription>
        )}

        {error && <Error id="auth-error">{error.message}</Error>}

        <Form loading={loading}>
          <FormInput
            type="email"
            placeholder={intl.messages.EMAIL_PLACEHOLDER}
            name="email"
            value={email}
            disabled={!!fixedEmail || isSoundCloudVerification}
            onChange={this.formChange}
            onKeyUp={this.inputKeyUp}
          />
          <FormInput
            type="password"
            placeholder={intl.messages.PASSWORD_PLACEHOLDER}
            name="password"
            value={password}
            onChange={this.formChange}
            onKeyUp={this.inputKeyUp}
          />

          <ForgotPassLink>
              <Link to="/forgot">
                <InlineSVG
                    element="div"
                    src={require(`!svg-inline-loader!@featurefm/common/assets/icons/lock.svg`)}
                />
                <FormattedMessage id="FORGOT_LINK" />
            </Link>
          </ForgotPassLink>
        </Form>

        <Button
          id="button-login"
          variant="accent"
          onClick={this.login}
          disabled={this.submitReady()}
          loading={loading}
        >
          {!loading && <FormattedMessage id="LOGIN" />}
          {loading && <LoadingContainer id="button-login-loading"><ScaleLoader color={'#fff'} /></LoadingContainer>}
        </Button>
        {theme !== "ada" &&
        <>
          {!emailAccountOnly && (
              <>
                <Separator text={ intl.messages.OR_SEPARATOR } />
                <Button id="button-fb-login" variant="facebook" onClick={this.facebookLogin}>
                  <FontAwesomeIcon icon={faFacebookF} />
                  <FormattedMessage id="LOGIN_FACEBOOK_BUTTON" />
                </Button>
                { SOUNDCLOUD_ENABLED === "true" && <Button id="button-sc-login" variant="soundcloud" onClick={this.soundCloudLogin}>
                  <FontAwesomeIcon icon={faSoundcloud} />
                  <FormattedMessage id="LOGIN_SOUNDCLOUD_BUTTON" />
                </Button>}

                <Subtext>
                  <FormattedMessage id="FACEBOOK_DISCLAIMER" />
                </Subtext>
              </>
          )}

          <SingupLink>
            {!isSoundCloudVerification && <FormattedMessage
              id="SIGNUP_LINK"
              values={{
              link: <Link id="link-signup" to={`${routePrefix || ''}/signup${queryString || ''}`}>{ intl.messages.SIGNUP } →</Link>
              }}
            />}
          </SingupLink>
        </>}
        {theme === "ada" &&
          <AdaPlaceHolder/>
        }
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  mobile: state.App.mobile,
  loading: state.Auth.loading,
  targetEmail: state.Auth.targetEmail,
  theme: state.App.theme,
  error: state.Auth.error,
  location: state.router.location
});

const mapDispatchToProps = {
  login,
  facebookLogin,
  soundCloudLogin
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(LoginModal));
