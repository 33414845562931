import styled from 'styled-components';
import Color from "color";

export const SwitchContainer = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  margin: 36px auto;
  height: 54px;

  @media (max-width: 768px) {
    margin: 36px auto;
  }
`;

export const SwitchLabel = styled.div`
  font-size: 18px;
  line-height: 16px;
  font-weight: bold;
  text-align: left;
  cursor: pointer;

  color: ${props => props.active ? props.theme.textMain : Color( props.theme.textMain ).alpha( 0.5 ).rgb().string() };

  ${props =>
    !props.active &&
    `
    &:hover {
      color: ${ Color( props.theme.textMain ).alpha( 0.9 ).rgb().string() };
    }
  `}

  small {
    font-size: 12px;
    color: ${ props => props.theme.mutedText };
  }
`;

export const SwitchCircle = styled.div`
  position: relative;
  width: 32px;
  height: 32px;

  border-radius: 50%;

  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);
  border: 10px solid #fff;

  transition: transform 0.2s ease-out;
`;

export const Switch = styled.div`
  width: 62px;
  height: 38px;
  border-radius: 32px;
  background-image: linear-gradient(304deg, #00f2fe, #4facfe);

  margin: 0px 24px;
  padding: 3px;

  ${SwitchCircle}:before,
  ${SwitchCircle}:after {
    border-color: rgba(26, 167, 224, 0.2);
  }

  ${props =>
    props.enabled &&
    `
    background-image: linear-gradient(238deg, #a445b2, #d41872 52%, #ff0066);

    ${SwitchCircle}:before,
    ${SwitchCircle}:after {
      border-color: rgba(254, 68, 125, 0.2);
    }
    ${SwitchCircle} {
      transform: translate( 24px );
    }
  `}

  @media ( max-width: 768px ) {
    margin: 0px 12px;
  }
`;