import _ from "lodash";
import update from "immutability-helper";

import Validators from "../validators";

/**
 * Check the value :)
 *
 * This function should be bound to component's scope
 * like this
 *
 * ```
 *  this.validateFieldDebounced = _.debounce(validateField.bind(this), 500);
 * ```
 * @param {String} key - key to check
 * @param {String} value - value to check
 */
export function validateField(key, value) {
  if (typeof Validators[key] !== "function") {
    return;
  }

  const result = Validators[key](value);

  if (this.formKey) {
    const form = this.state[this.formKey];

    this.setState({
      [this.formKey]: result === true
        ? update(form, { errors: { $unset: [key] } })
        : update(form, { errors: { $merge: { [key]: result } } })
    });
  } else {
    this.setState({
      errors:
        result === true
          ? update(this.state.errors, { $unset: [key] })
          : update(this.state.errors, { $merge: { [key]: result } })
    });
  }

  if (this.onFieldError && result !== true) {
    this.onFieldError(key, value, result);
  }
}

/**
 * Check if submit should be enabled based on
 * prescense of errors
 *
 * should be bound to component's scope
 *
 * Mb it's not as general as I think :)
 */
export function submitReady() {
  const { errors } = this.state;

  const form = _.pick(this.state, this.formFields);

  return _.includes(form, "") || (errors && !_.isEmpty(errors));
}

export function formChange(e) {
  const key = e.target.name;
  const value = e.target.value;

  if (typeof this.validateFieldDebounced === "function") {
    this.validateFieldDebounced(key, value);
  }

  if (this.formKey) {
    const form = this.state[this.formKey];
    this.setState({
      [this.formKey]: form
        ? update(form, { $merge: { [key]: value } })
        : { [key]: value }
    });
  } else {
    this.setState({
      [key]: value
    });
  }
}
