import styled, { createGlobalStyle } from 'styled-components';

import { flex, breakpointMax, rgba } from '../styled/mixins';
import { InputContainer } from '../FormInput/styled';

export const DropdownContainer = styled( InputContainer )`
  cursor: pointer;

  .ffm-form-select {
    width: 100%;
    height: 100%;

    font-size: 16px;
    font-weight: 500;

    box-sizing: border-box;
    border-radius: 6px;
    border: none;

    color: ${ props => props.theme.textMain };

    position: relative;
    z-index: 1;

    ${ props => props.focused ? `
      background: ${ props.theme.inputBgFocus };
    ` : `
      background: ${ props.theme.inputBgIdle };
    `}

    ${ breakpointMax( 'md', `
      font-size: 14px;
    ` ) }
  }

  .ffm-form-select__control {
    border: none;

    width: 100%;
    height: 100%;
  }

  .ffm-form-select__value-container {
    padding: 0px 18px;
  }

  .ffm-form-select__placeholder {
    color: ${ props => props.theme.textMain };
    opacity: 0.2;
  }

  .ffm-form-select__indicator-separator {
    display: none;
  }

  .ffm-form-select__dropdown-indicator,
  .ffm-form-select__dropdown-indicator:hover {
    color: ${ props => props.focused ? rgba( props.theme.inputBorderFocus, 0.4 ) : rgba( props.theme.textMain, 0.4 ) };
  }

  &:hover .ffm-form-select__dropdown-indicator {
    color: ${ props => props.focused ? props.theme.inputBorderFocus : props.theme.textMain };
  }
`

export const DropdownMenuGlobalStyles = createGlobalStyle`
  .ffm-app .ffm-form-select__menu {
    border: ${ props => props.borderWidth || 2 }px solid ${ props => props.theme.inputBorderIdle };
    border-radius: ${ props => props.borderRadius || 8}px !important;
    box-shadow: 0px 2px 6px 0px ${ rgba( '#000', 0.1 ) };
    overflow: hidden
  }

  .ffm-app .ffm-form-select__menu-list {
    padding: 0px;
  }

  .ffm-app .ffm-form-select__single-value,
  .ffm-app .ffm-form-select__option {
    ${ flex }
  }

  .ffm-app .ffm-form-select__option {
    align-items: center;
    cursor: pointer;

    font-size: ${ props => props.fontSize || 16}px;
    line-height: ${ props => props.lineHeight || 18}px;
    height: ${ props => props.optionHeight || 60 }px;

    background: ${ props => props.theme.inputBgIdle };

    padding: 0px 18px;
  }

  .ffm-app .ffm-form-select__option:hover {
    background: ${ props => props.theme.inputBgFocus };
  }

  .ffm-app .ffm-form-select__option.ffm-form-select__option--is-selected {
    background: ${ props => rgba( props.theme.primary, 0.2 ) };
    color: ${ props => props.theme.primary };
    font-weight: 700;
  }
`;