import styled, { css } from 'styled-components';
import Color from 'color'
import { COLORS } from '../../config';

import { flex, flexCol, flexCenter, pinAbsolute, transition, breakpointMax } from '../styled/mixins'


//  Not sure if needs to be common
export const Input = styled.input`
  width: 100%;
  height: 100%;

  font-size: 16px;
  font-weight: 500;
  padding: 0px 18px;

  box-sizing: border-box;
  border-radius: 6px;
  border: none;

  color: ${ props => props.theme.textMain };

  :focus {
    outline: none;
  }

  ::placeholder {
    opacity: 0.6;
  }

  ${ breakpointMax( 'md', `
    font-size: 16px;
  ` ) }
`

export const InputGroup = styled.div`
  ${ flex }
  ${ flexCol }

  min-height: 60px;
  width: ${ props => props.width ? props.width : '100%' };
`;

export const InputPrefix = styled.div`
  ${ flex }
  ${ flexCenter }

  position: relative;
  width: 46px;
  height: 46px;

  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;

  color: ${ props => Color( props.theme.dusk ).alpha(0.4).rgb().string() };

  & + ${ Input } {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
  }
`;

//  Not sure if needs to be common
export const InputContainer = styled.div`
  ${ flex }
  position: relative;

  width: 100%;
  height: 60px;

  padding: 2px;
  border-radius: 8px;
  overflow: hidden;

  ${ Input } {
    position: relative;
    z-index: 1;

    ${ props => props.focused ? `
      background: ${ props.theme.inputBgFocus };
    ` : `
      background: ${ props.theme.inputBgIdle };
    `}
  }

  ${ InputPrefix } {
    margin-right: 2px;
    z-index: 1;

    ${ props => props.focused ? `
      background: ${ props.theme.inputPrefixBgFocus };
      color: ${ props.theme.inputBgIdle };
    ` : `
      background: ${ props.theme.inputPrefixBgIdle };
      color: ${ props.filled ? props.theme.dusk : Color( props.theme.dusk ).alpha(0.4).rgb().string() };
    `}
  }

  :before {
    content: " ";

    ${ pinAbsolute }

    z-index: 0;

    ${ props => props.focused ? `
      background: ${ props.theme.inputBorderFocus };
    ` : `
      background: ${ props.theme.inputBorderIdle };
    `}
  }

  ${ breakpointMax( 'md', `
    height: 50px;
  ` ) }

  ${ props => props.error && css`
    :before { background: ${ props.theme.danger }; }
    ${ Input } { background: ${ props.theme.inputBgError } }
    ${ InputPrefix } {
      background: ${ props.theme.inputBgError };
      color: ${ props.theme.inputBgIdle };
    }
  ` }
`;

export const InputError = styled.div`
  font-size: 12px;
  color: ${ props => props.theme.danger };
  line-height: 1.4em;
  margin-top: 7px;

  width: 100%;

  white-space: pre-line;
`

export const FormGroup = styled.section`
  ${ flex }

  justify-content: space-between;
  flex-wrap: wrap;

  ${ InputGroup } {
    max-width: calc( 50% - 8px );
    width: auto;
    flex-grow: 1;

    margin: 0px !important;
  }
`;

export const FormLabel = styled.label`
  font-size: 16px;
  line-height: 19px;

  color: ${ props => props.theme.textMain };

  ${ props => props.required && `
    :after {
      content: "*";
      color: ${ props.theme.danger };
      font-variant-position: super;
    }
  ` }
`;

export const LoadingContainer = styled.span`
  position: absolute;

  ${ flex }
  ${ flexCenter }

  width: 100%;
  height: 100%;

  top: 0px;
  left: 0px;
  
  justify-content: flex-end;
  padding-right: 18px;
      
  > div {
    z-index: 1;
  }
`;

