import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

function getDescriptionMessage( page ) {
  if (page.indexOf('pricing') > 0) {
    return 'Get started with free music Smart Links, free Pre-Saves, professional music marketing tools and API solution.';
  } else if (page.indexOf('about') > 0) {
    return "We're passionate about music and technology. We want to help artists get discovered in today's music landscape.";
  } else if (page.indexOf('for-artists') > 0) {
    return 'Are you an artist who wants leading music marketing tools? Try our free ffm.to music Smart Links, free Pre-Saves and more.';
  } else if (page.indexOf('for-marketers') > 0) {
    return "Use the industry's leading music marketing platform for professionals. Advanced smart links, pre-saves, fan insights, retargeting, and more.";
  } else if (page.indexOf('for-enterprise') > 0) {
    return 'Leverage the most powerful music marketing API trusted by the music industry.';
  }
}

export default function Meta({page, title}) {
  const description = 'All-in-one music marketing and data platform with Music Smart Links, Pre-Save, Follow Gates, Music Advertising, Fan CRM, and Audience Analytics.';

  return (
    <Helmet>
      <title>{ title }</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={`https://feature.fm${page}`} />

      <meta
        name="keywords"
        content="music promotion, music smart links, smartlinks, smart links, smart-links, pre-add, pre-add apple music, pre-save music, pre-save, spotify, artist promotion,smart link for music marketing,music marketing, music campaign"
      />
      <meta property="og:site_name" content="Feature.fm" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={ title } />
      <meta
        property="og:description"
        content="We build smart marketing and advertising tools for the music industry"
      />

      <meta property="og:url" content={`https://feature.fm${page}`} />
      <meta property="og:image" content="/static/og.jpg" />
      <meta property="og:image:width" content="2880" />
      <meta property="og:image:height" content="1660" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={ title } />
      <meta
        name="twitter:description"
        content="We build smart marketing and advertising tools for the music industry"
      />
      <meta name="twitter:url" content={`https://feature.fm${page}`} />
      <meta name="twitter:image" content="/static/og.jpg" />
      <meta name="twitter:site" content="@Featurefm" />
    </Helmet>
  );
}
