import styled, { css } from 'styled-components';

import { flex, flexCol, flexCenter, aspectRatio, transition, rgba, breakpointMin, buttonReset, breakpointMax } from '../styled/mixins';



export const InputLabel = styled.div`
  font-size: 16px;
  line-height: 18px;
`;

const guidlinesCommon = css`
  font-size: 10px;
  line-height: 12px;

  white-space: pre-line;
  text-align: center;

  z-index: 3;

  position: absolute;

  left: 16px;
  bottom: 16px;

  padding: 1em 0px;

  width: calc( 100% - 32px );

  color: ${ props => props.color ? props.theme[ props.color ] : props.theme.mutedText };

  ${ transition( 'common', 'opacity' ) }
`;

export const InputGuidelines = styled.div`
  ${ guidlinesCommon }

  pointer-events: none;
`;

export const ClearButton = styled.button`
  ${ buttonReset( 'transparent' ) }
  ${ guidlinesCommon }

  padding: 0.2em 1em;
  border-radius: 2px;

  :hover {
    background: ${ props => props.theme.inputBgIdle } !important;
    color: ${ props => props.theme.textMain } !important;
  }
`

export const Container = styled.div`
  ${ aspectRatio( 1, 1 ) }

  width: ${ props => props.w }px;
  cursor: pointer;

  background: ${ props => props.theme.inputBgIdle};
  border: 1px solid ${ props => props.theme.inputBorderIdle};

  border-radius: 6px;

  overflow: hidden;

  & > .content {
    ${ flex }
    ${ flexCol }
    ${ flexCenter }

    z-index: 2;

    color: ${ props => props.theme.primary };

    ${ transition( 'common', 'transform, opacity' ) }
  }

  & > .content.image {
    z-index: 1;

    img {
      width: 100%;
      height: 100%;

      object-fit: contain;
    }
  }

  .svg-inline--fa {
    width: 34px;
    height: 34px;
    margin-bottom: 1em;
  }

  ${ props => !props.loading && css`
    ${ breakpointMin( 'md', `
      &:hover {
        background: ${ props.theme.inputBgFocus };
      }
    `)}
  `}

  ${ props => props.hasImage && !props.loading && css`

    & > .content:not(.image),
    ${ ClearButton },
    ${ InputGuidelines } {
      opacity: 0;
      color: ${ props.theme.inputBgIdle };
    }

    & > .content:not(.image) {
      background: ${ props => rgba( props.theme.primary, 0.85 ) };
    }
    
    ${ breakpointMin('md', `
      &:hover > .content:not(.image),
      &:hover ${ ClearButton } {
        opacity: 1;
      }
    `)}
  ` }

  ${ props => (props.isDragActive || props.loading) && css`
    background: ${ props => props.theme.primary };
    border-color: ${ props => props.theme.primary };

    & > .content {
      transform: scale(1.1);
      color: ${ props.theme.inputBgIdle };
    }

    ${ InputGuidelines } {
      opacity: 0;
    }
  ` }

  ${ props => props.hasImage && props.isDragActive && css`
    & > .content:not(.image) {
      opacity: 1;
    }
  ` }

  ${ props => !props.hasImage && !props.isDragActive && !props.loading && css`
    :after {
      content: " ";
      display: block;

      position: absolute;

      top: 16px;
      left: 16px;

      width: calc( 100% - 32px );
      height: calc( 100% - 32px );

      border: 2px dashed ${ rgba( '#000', 0.1 ) };
    }
  ` }

  ${ breakpointMax( 'md', `
    width: 100%;
  ` ) }
`;
