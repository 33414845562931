import { SEGMENTIO_ENABLED, SEGMENTIO_WRITE_KEY } from '@featurefm/common/config/env';

export default {
  load: () => {
    if ( SEGMENTIO_ENABLED === 'true' ) {
      window.analytics.load( SEGMENTIO_WRITE_KEY );
    }
  },
  page: () => {
    if ( SEGMENTIO_ENABLED === 'true' ) {
      window.analytics.page();
    }
  },
  track: (name, properties) => {
    if ( SEGMENTIO_ENABLED === 'true' ) {
      window.analytics.track(name, properties);
    }
  }
};
