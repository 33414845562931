import actions, { isMobile, getView } from './actions';

const initialView = getView(window.innerWidth)
const initialState = {
  view: initialView,
  mobile: isMobile(initialView),
  height: window.innerHeight,
  width: window.innerWidth,
  loading: true,

  dest: null,
  impersonate: null,

  theme: process.env.REACT_APP_DEFAULT_THEME || "dark",


  locations: null,
  accountTypes: null
}

export default (state = initialState, action ) => {
  switch (action.type) {

  case actions.RESIZE:
    const view = getView(action.width);
    const mobile = isMobile(view);

    return {
      ...state,
      width: action.width,
      height: action.height,
      view,
      mobile
    }

  case actions.SWITCH_THEME:
    return {
      ...state,
      theme: action.theme
    }

  case actions.SET_LOADED:
    return {
      ...state,
      loading: false
    }

  case actions.SET_LOADING:
    return {
      ...state,
      loading: true
    }

  case actions.SET_DEST:
    return {
      ...state,
      dest: action.dest
    }

  case actions.SET_IMPERSONATE:
    return {
      ...state,
      impersonate: action.impersonate
    }

  default:
    return state
  }
}
