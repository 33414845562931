import React, { Component } from "react";
import { connect } from "react-redux";

import { acceptInvitation } from "../../redux/acceptInvitation/actions";

import LoadingScreen from "../LoadingScreen";
import AcceptedUserDelegationInvitation from "./components/AcceptedUserDelegationInvitation";
import AcceptInvitationError from "./components/AcceptInvitationError";
import ConvertArtistToPromoterModal from "./components/ConvertArtistToPromoterModal";
import {LoginContainer, AcceptInviteModal as Modal} from "../Login/styled";
import PerfectScrollbar from "react-perfect-scrollbar";
import Header from "../../components/common/Header";
import { HeaderLogoContainer } from "../../components/common/Header/styled";
import { Logo } from "../AcceptTerms/styled";

class AcceptInviteLoading extends Component {
  state = {
  };

  componentDidMount() {
    this.props.acceptInvitation(false);
  }

  AcceptInviteContent() {
    const { appLoading, isAcceptingInvitation, isAcceptedUserDelegationInvitation, acceptError, isConversionRequired } = this.props;

    return (
      <Modal id="accept-invite-modal">
        {(appLoading || isAcceptingInvitation) && (<LoadingScreen />)}
        {!appLoading && !isAcceptingInvitation && isAcceptedUserDelegationInvitation && (<AcceptedUserDelegationInvitation />)}
        {!appLoading && !isAcceptingInvitation && isConversionRequired && (<ConvertArtistToPromoterModal />)}
        {!appLoading && !isAcceptingInvitation && acceptError && (<AcceptInvitationError />)}
      </Modal>
    );
  }

  render() {
    const { mobile, role } = this.props;

    return <>
      {!mobile && (
        <LoginContainer id="accept-invite-container" justifyBreakpoint={ role === 'promoter' ? '780px' : null }>
          <PerfectScrollbar>
            <Header><HeaderLogoContainer><Logo /></HeaderLogoContainer></Header>
            {this.AcceptInviteContent()}
          </PerfectScrollbar>
        </LoginContainer>
      )}

      {mobile && (<>
        <Header><HeaderLogoContainer noPadding><Logo /></HeaderLogoContainer></Header>
        {this.AcceptInviteContent()}
      </>)}
    </>
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
  appLoading: state.App.loading,
  isAcceptingInvitation: state.AcceptInvitation.isAccepting,
  isAcceptedUserDelegationInvitation: state.AcceptInvitation.isAcceptedUserDelegationInvitation,
  isConversionRequired: state.AcceptInvitation.isConversionRequired,
  acceptError: state.AcceptInvitation.error,
});

const mapDispatchToProps = {
  acceptInvitation
};

export default connect(mapStateToProps, mapDispatchToProps)(AcceptInviteLoading);
