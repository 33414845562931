import {FFM_CONSOLE_API_URL, MIXPANEL_ENABLED, MIXPANEL_KEY} from '@featurefm/common/config/env';
import mixpanel from 'mixpanel-browser';
import axios from 'axios';

export default {
  load: () => {
    if ( MIXPANEL_ENABLED === 'true' ) {
      mixpanel.init(MIXPANEL_KEY, { debug: true, track_pageview: true, batch_requests: false, persistence: 'localStorage' });
    }
  },
  identify: (userId) => {
    if ( MIXPANEL_ENABLED === 'true' ) {
      mixpanel.identify(userId)
    }
  },
  pageview: () => {
    if ( MIXPANEL_ENABLED === 'true' ) {
      mixpanel.track_pageview();
    }
  },
  track: (name, properties) => {
    if ( MIXPANEL_ENABLED === 'true' ) {
      mixpanel.track(name, properties);
    }
  },
  registerSuperProperties: (obj) => {
    if ( MIXPANEL_ENABLED === 'true' ) {
      mixpanel.register(obj);
    }
  },
  /*
  setUserProfileProperties: (obj) => {
    if ( MIXPANEL_ENABLED === 'true' ) {
      mixpanel.people.set(obj);
    }
  },
   */
  refreshUserProfileProperties: async () => {
    if ( MIXPANEL_ENABLED === 'true' ) {
      try {
        const axiosInstance = axios.create({
          baseURL: FFM_CONSOLE_API_URL,
          withCredentials: true
        });
        await axiosInstance.get('/refresh-mixpanel-user-data')
      } catch (error) {
        console.error('Error during mixpanel data refresh:', error);
      }
    }
  },
};
