import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons";
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import InlineSVG from 'svg-inline-react';
import { ScaleLoader } from 'react-spinners';
import { injectIntl } from 'react-intl'

import {register, facebookLogin, aggregateFieldError} from "../../../redux/auth/actions";

import { TOS_URL, PP_URL } from "@featurefm/common/config";
import Logo from "@featurefm/common/components/Logo";
import { validateField, submitReady, formChange } from "@featurefm/common/helpers/form";

import FormInput, { FormGroup } from "@featurefm/common/components/FormInput";

import {
  LoginModal as Modal,
  Form,
  Button,
  LoginTitle,
  Separator,
  Subtext,
  SingupLink,
  RoleContainer,
  RoleButtonsGroup,
  RoleButton,
  RoleButtonLabel,
  Error,
  LoadingContainer,
  SuccessIcon, ArtistRoleButton, RepresentsRoleButton
} from "../../Login/styled";

class SignupModal extends Component {
  state = {
    firstName: "",
    lastName: "",
    companyName: "",
    email: this.props.fixedEmail || "",
    password: "",
    errors: {}
  };

  register = e => {
    const { register, role, variant, currency, plan, aggregatedErrors } = this.props;
    const form = _.pick(this.state, this.formFields);

    form.userType = role;
    form.role = role;
    form.variant = variant;
    form.currency = currency;
    form.plan = plan;
    form.aggregatedErrors = aggregatedErrors;

    register(form);
  };

  facebookLogin = e => {
    const { facebookLogin, role, location } = this.props;

    const forceStubs = location.query[ 'force-stubs' ] === "true" || location.query[ 'force-stubs' ] === true;
    facebookLogin({ role, forceStubs });
  }

  get formFields() {
    const { role, currency, plan } = this.props;

    const baseFields = [
      "role",
      "firstName",
      "lastName",
      "email",
      "password"
    ]
    .concat(currency ? ["currency"] : [])
    .concat(plan ? ["plan"] : []);

    return role === 'promoter' ? _.union( baseFields, [ "companyName" ]) : baseFields;
  }

  inputKeyUp = e => {
    if ( e.key === "Enter" ) {
      this.register();
    }
  }

  constructor(props) {
    super(props);

    this.validateFieldDebounced = _.debounce(validateField.bind(this), 500);
    this.submitReady = submitReady.bind( this );
    this.formChange = formChange.bind(this);
  }

  onFieldError(name, value, error) {
    this.props.aggregateFieldError(name, value, error);
  }

  render() {
    const { role, mobile, isFixedRole, loading, error, selectRole, registerSuccess, intl, fixedEmail, emailAccountOnly, routePrefix, queryString } = this.props;
    const {
      email,
      password,
      companyName,
      firstName,
      lastName,
      errors,
    } = this.state;
    return (<>
      { registerSuccess &&
        <RoleContainer>
          <Logo />

          <SuccessIcon style={{marginTop: 128}}>
            <FontAwesomeIcon icon={ faCheck } />
          </SuccessIcon>

          <LoginTitle style={{ margin: '1em 0px'}}>
            { intl.formatMessage( { id: 'SUCCESS_TITLE' } ) }
          </LoginTitle>
          <Subtext>
            { intl.formatMessage( { id: 'SIGNUP_SUCCESS' }, { email: (<strong>{ email }</strong>) } ) }
          </Subtext>
        </RoleContainer>
      }

      { !registerSuccess && !role && (
        <RoleContainer>
          <Logo />

          <RoleButtonsGroup>
            <ArtistRoleButton onClick={ e => !!e.target && selectRole('artist') } id="button-artist">
              <InlineSVG
                element="div"
                src={require(`!svg-inline-loader!@featurefm/common/assets/icons/role-artist.svg`)}
              />
              <RoleButtonLabel>
                { intl.formatMessage( { id: 'SIGNUP_ARTIST' } ) }
              </RoleButtonLabel>
            </ArtistRoleButton>
            <RepresentsRoleButton onClick={ e => !!e.target && selectRole('promoter') } id="button-promoter">
              <InlineSVG
                element="div"
                src={require(`!svg-inline-loader!@featurefm/common/assets/icons/role-manager.svg`)}
              />
              <RoleButtonLabel>
                { intl.formatMessage( { id: 'SIGNUP_PROMOTER' } ) }
              </RoleButtonLabel>
            </RepresentsRoleButton>
          </RoleButtonsGroup>
        </RoleContainer>
      ) }
      { !registerSuccess && role &&
      <Modal id="signup-modal">
        <Logo />

        {!mobile &&
          <LoginTitle>
            { intl.formatMessage( { id: 'SIGNUP_TITLE' } ) }
          </LoginTitle>
        }

        { error && <Error id="auth-error">{error.message}</Error> }

        <Form loading={loading}>
          <FormGroup>
            <FormInput
              error={firstName.length > 0 && errors.firstName }
              type="text"
              name="firstName"
              placeholder={ intl.messages.FIRSTNAME_PLACEHOLDER }
              value={firstName}
              onChange={this.formChange}
              onKeyUp={this.inputKeyUp}
            />
            <FormInput
              error={lastName.length > 0 && errors.lastName }
              type="text"
              name="lastName"
              placeholder={ intl.messages.LASTNAME_PLACEHOLDER }
              value={lastName}
              onChange={this.formChange}
              onKeyUp={this.inputKeyUp}
            />
          </FormGroup>

          { role === 'promoter' && <FormInput
            error={companyName.length > 0 && errors.companyName }
            type="text"
            placeholder={ intl.messages.COMPANYNAME_PLACEHOLDER }
            name="companyName"
            value={companyName}
            onChange={this.formChange}
            onKeyUp={this.inputKeyUp}
          /> }

          <FormInput
            error={email.length > 0 && errors.email }
            type="email"
            placeholder={ intl.messages.EMAIL_PLACEHOLDER }
            name="email"
            value={email}
            disabled={!!fixedEmail}
            onChange={this.formChange}
            onKeyUp={this.inputKeyUp}
          />
          <FormInput
            error={password.length > 0 && errors.password }
            type="password"
            placeholder={ intl.messages.PASSWORD_PLACEHOLDER }
            name="password"
            value={password}
            onChange={this.formChange}
            onKeyUp={this.inputKeyUp}
          />
        </Form>

        <Button id="button-signup" variant="accent" disabled={ this.submitReady() } loading={ loading } onClick={this.register}>
          { !loading && intl.messages.SIGNUP }
          { loading && <LoadingContainer id="button-signup-loading"><ScaleLoader color={'#fff'} /></LoadingContainer> }
        </Button>

        <Subtext>
          { intl.formatMessage( { id: 'TOS_DISCLAIMER' }, {
            tos: (...chunks) => (<a href={ TOS_URL } target="_blank" rel="noopener noreferrer" style={{color: '#9B62FF'}}>{chunks}</a>),
            pp: (...chunks) => (<a href={ PP_URL } target="_blank" rel="noopener noreferrer" style={{color: '#9B62FF'}}>{chunks}</a>)
          } ) }
        </Subtext>

        {!emailAccountOnly && (
          <>
            {/*  */}
            <Separator text="OR" />
            {/*  */}

            <Button id="button-fb-signup" variant="facebook" onClick={ this.facebookLogin }>
              <FontAwesomeIcon icon={faFacebookF} />
              <span>{ intl.formatMessage( { id: 'SIGNUP_FACEBOOK_BUTTON' } ) }</span>
            </Button>

            <Subtext>
              { intl.formatMessage( { id: 'FACEBOOK_DISCLAIMER' } ) }
            </Subtext>
          </>
        )}

        <SingupLink id="link-login">
          { intl.formatMessage( { id: 'LOGIN_LINK' }, { link: <Link to={`${routePrefix || ''}/login${isFixedRole && queryString ? queryString : ''}`}>{ intl.messages.LOGIN } →</Link> } ) }
        </SingupLink>
      </Modal>
      }
      </>
    );
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
  loading: state.Auth.loading,
  error: state.Auth.error,
  aggregatedErrors: state.Auth.aggregatedErrors,
  registerSuccess: state.Auth.registerSuccess,
  location: state.router.location
});

const mapDispatchToProps = {
  register, facebookLogin, aggregateFieldError
};

export default connect(mapStateToProps, mapDispatchToProps)( injectIntl(SignupModal) );
