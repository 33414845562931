/**
 * Ported from CrowdFund
 */

/**
 * Track event with GTM
 *
 * @param {String} entity
 * @param {String} action
 * @param {Object} info
 */
export const track = (entity, action, info) => {
  try {
    if (window.FfmAnalyticsManager && window.FfmAnalyticsManager.track) {
      const title = action ? `[${entity}] ${action}` : `[${entity}]`;
      window.FfmAnalyticsManager.track(title, info);
    }
  } catch (e) {
    console.warn("GTM (window.FfmAnalyticsManager.track) error - ", e);
  }
};

/**
 * Track login event specifically
 *
 * @param {String} email
 * @param {Object} info
 */
export const login = (email, info) => {
  try {
    if (window.FfmAnalyticsManager && window.FfmAnalyticsManager.login) {
      window.FfmAnalyticsManager.login(email, info);
    }
  } catch (e) {
    console.warn("GTM (window.FfmAnalyticsManager.login) error - ", e);
  }
};

/**
 * Track logout event specifically
 */
export const logout = () => {
  try {
    if (window.FfmAnalyticsManager && window.FfmAnalyticsManager.logout) {
      window.FfmAnalyticsManager.logout();
    }
  } catch (e) {
    console.warn("GTM (window.FfmAnalyticsManager.logout) error - ", e);
  }
};

/**
 * Track signup event specifically
 *
 * @param {String} email
 * @param {Object} info
 */
export const signup = (email, info) => {
  try {
    if (window.FfmAnalyticsManager && window.FfmAnalyticsManager.signup) {
      window.FfmAnalyticsManager.signup(email, info);
    }
  } catch (e) {
    console.warn("GTM (window.FfmAnalyticsManager.signup) error - ", e);
  }
};

/**
 * Track routeChanged event
 *
 * @param {String} path
 */
export const routeChanged = path => {
  try {
    if (window.FfmAnalyticsManager && window.FfmAnalyticsManager.routeChanged) {
      window.FfmAnalyticsManager.routeChanged(path);
    }
  } catch (e) {
    console.warn("GTM (window.FfmAnalyticsManager.routeChanged) error - ", e);
  }
};

export const identify = (email) => {
  try {
    if (window.FfmAnalyticsManager && window.FfmAnalyticsManager.identify) {
      window.FfmAnalyticsManager.identify(email);
    }
  } catch (e) {
    console.warn("GTM (window.FfmAnalyticsManager.identify) error - ", e);
  }
};

export const identifyForError = (email) => {
  try {
    if (window.FfmAnalyticsManager && window.FfmAnalyticsManager.error) {
      window.FfmAnalyticsManager.error(email, {}, {});
    }
  } catch (e) {
    console.warn("GTM (window.FfmAnalyticsManager.error) error - ", e);
  }
};

export const purchaseSubscription = (email, info) => {
  try {
    if (
      window.FfmAnalyticsManager &&
      window.FfmAnalyticsManager.purchaseSubscription
    ) {
      window.FfmAnalyticsManager.purchaseSubscription(email, info);
    }
  } catch (e) {
    console.warn(
      "GTM (window.FfmAnalyticsManager.purchaseSubscription) error - ",
      e
    );
  }
};
