import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import { injectIntl } from 'react-intl'

import {
  LoginTitle,
  RoleContainer,
  ConvertRoleDescription, WarningIcon, SingupLink, ConvertButton, LoadingContainer,
} from "../../Login/styled";
import {FFM_CONSOLE_NEXT_REDIRECT_PATH, FFM_CONSOLE_NEXT_WEB_URL} from "@featurefm/common/config/env";
import { addProtocolToUrl } from "@featurefm/common/helpers/url";
import {ScaleLoader} from "react-spinners";

class AcceptedUserDelegationInvitation extends Component {
  state = {
    loading: false,
  };

  continueToSite = e => {
    this.setState({ loading: true });
    setTimeout(() => {
      window.location.href = FFM_CONSOLE_NEXT_WEB_URL + FFM_CONSOLE_NEXT_REDIRECT_PATH;
    })
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { intl, confirmationErrorId } = this.props;
    const { loading } = this.state;

    let errorText = intl.messages.VERIFY_EMAIL_ERROR_SUBTITLE;
    if (intl.messages && intl.messages[confirmationErrorId]) {
      errorText = intl.messages[confirmationErrorId];
    }

    return (<>
        <RoleContainer>
          <WarningIcon>
            <FontAwesomeIcon icon={ faExclamationTriangle } />
          </WarningIcon>

          <LoginTitle style={{ margin: '1em 0px'}}>
            { intl.messages.VERIFY_EMAIL_ERROR_TITLE }
          </LoginTitle>
          <ConvertRoleDescription>
            { errorText }
          </ConvertRoleDescription>

          <ConvertButton id="button-continue-to-site" variant="accent" loading={ loading } onClick={this.continueToSite}>
            { !loading && intl.messages.CONTINUE_TO_SITE }
            { loading && <LoadingContainer id="button-continue-to-site-loading"><ScaleLoader color={'#fff'} /></LoadingContainer> }
          </ConvertButton>

        </RoleContainer>
      </>
    );
  }
}

const mapStateToProps = state => ({
  mobile: state.App.mobile,
  confirmationErrorId: state.Auth.errorId,
});

export default connect(mapStateToProps)( injectIntl(AcceptedUserDelegationInvitation) );
