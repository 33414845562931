import React, { Component } from 'react';
import { connect, Provider } from 'react-redux';
import mixpanel from '../utils/mixpanel'
import Favicon from 'react-favicon';
import { store, history } from '../redux/store';
import { ConnectedRouter } from 'connected-react-router';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'styled-components';
import Intercom, { update } from "@intercom/messenger-js-sdk";

import THEMES from '@featurefm/common/config/themes';

//  Actions
import { resize } from '../redux/app/actions';
import { getUser } from '../redux/auth/actions';

import Router from './Router';

import { OVERSCROLL_BG_ROUTES, LEGACY_THEME_ROUTES, INTERCOM_CONFIG } from '../configs';
import { supportedLocales, getMessages } from '../intl';

import { GlobalStyle } from './Main.styled';
import segmentio from '../utils/segmentio';
import Meta from '@featurefm/common/containers/Meta';
import { getIntercomUser } from '../utils/intercom';

class Main extends Component {
  /**
   * Set all viewport related properties in state on resize
   *
   * @param {object} event - resize event
   */
  resize = event => {
    const { resize, collapsedBreakpoint } = this.props;
    resize( window.innerWidth, window.innerHeight, collapsedBreakpoint );
  };

  componentDidMount() {
    // const { getUser } = this.props;

    window.addEventListener('resize', this.resize);

    //  Let's not do this just yet :)
    // const token = localStorage.getItem('auth0_token');

    // if (token) {
    //   getUser();
    // }
  }

  // if user changes, we should load intercom
  componentDidUpdate(prevProps) {
    if (prevProps.intercomUser !== this.props.intercomUser) {
      update({
        ...(this.props.intercomUser || {}),
      })
    }
  }


  constructor( props ) {
    super( props );
    segmentio.load();
    mixpanel.load();

    const { intercomUser } = this.props;

    Intercom({
      ...INTERCOM_CONFIG,
      ...(intercomUser || {}),
    })
  }

  render() {
    const { router, theme } = this.props;
    const activeTheme = LEGACY_THEME_ROUTES.includes( router.location.pathname ) ? THEMES['legacy'] : THEMES[theme];

    const globalStyleProps = {
      overscrollBg: OVERSCROLL_BG_ROUTES[ router.location.pathname ]
    }

    const locale =
      typeof navigator !== 'undefined' && navigator.language in supportedLocales
        ? navigator.language
        : 'en';
    const messages = getMessages(locale);

    const intlOptions = { locale, messages };

    const pageTitle = messages[ `PAGE_TITLE_${ router.location.pathname }` ];

    //  Provider city :)
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ThemeProvider theme={activeTheme}>
            <IntlProvider {...intlOptions}>
              <GlobalStyle {...globalStyleProps} />

              <Meta page={ router.location.pathname } title={ pageTitle } />

              {/*<Favicon url={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_FAVICON || "favicon-32x32.png"}`} />*/}

              <main className="featurefm__main">
                <Router />
              </main>
            </IntlProvider>
          </ThemeProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default connect(
  state => ({
    router: state.router,
    theme: state.App.theme,
    mobile: state.App.mobile,
    intercomUser: getIntercomUser( state.Auth.user, state.Auth.basicUserData )
  }),
  { getUser, resize }
)(Main);
