export const OKTA_URL = process.env.REACT_APP_OKTA_URL || 'https://dev-453007.oktapreview.com';
export const OKTA_IDP = process.env.REACT_APP_OKTA_IDP || '0oaasg82hrBlLtxiU0h7';
export const OKTA_CLIENTID = process.env.REACT_APP_OKTA_CLIENTID || 'V6bf28A5Jwr3D6tZOeOk';

export const FFM_LANDING_PAGE_URL = process.env.REACT_APP_FFM_LANDING_PAGE_URL || '//dev.feature.fm:2368/';
export const FFM_CONSOLE_API_URL = process.env.REACT_APP_FFM_CONSOLE_API_URL || 'http://dev.feature.fm:3033';
export const FFM_CONSOLE_WEB_URL = process.env.REACT_APP_FFM_CONSOLE_WEB_URL || 'http://dev.feature.fm:4001';
export const FFM_CONSOLE_NEXT_WEB_URL = process.env.REACT_APP_FFM_CONSOLE_NEXT_WEB_URL || 'http://dev.feature.fm:3100';
export const FFM_CONSOLE_CDN_URL = process.env.REACT_APP_FFM_CONSOLE_CDN_URL || 'http://dev.feature.fm:4001';
export const FFM_CAMPAIGN_API_URL = process.env.REACT_APP_FFM_CAMPAIGN_API_URL || 'http://dev.feature.fm:8088';

export const FACEBOOK_APP = process.env.REACT_APP_FACEBOOK_APP || '2344168375817350';
export const SOUNDCLOUD_CLIENT = process.env.REACT_APP_SOUNDCLOUD_CLIENT || 'UJguvzEprldbNiDezpNI65xgCWOcQ8PJ';
export const SOUNDCLOUD_ENABLED = process.env.REACT_APP_SOUNDCLOUD_ENABLED || "false"
export const SOUNDCLOUD_REDIRECT = process.env.REACT_APP_SOUNDCLOUD_REDIRECT || 'http://localhost:8088/webhook/soundcloud/authorize';

export const FFM_SESSION_COOKIE_NAME = process.env.REACT_APP_FFM_SESSION_COOKIE_NAME || 'dev.connect.sid';
export const FFM_SESSION_HIJACK_PROTECTION_COOKIE_NAME = process.env.REACT_APP_FFM_SESSION_HIJACK_PROTECTION_COOKIE_NAME || 'f_user.dev';
export const FFM_COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || '.dev.feature.fm';

export const SEGMENTIO_ENABLED = process.env.REACT_APP_SEGMENTIO_ENABLED || 'true';
export const SEGMENTIO_WRITE_KEY = process.env.REACT_APP_SEGMENTIO_WRITE_KEY || '7AuMGd2puAwA1MtbEW6rFScSmoV08W1W';

export const FFM_CONSOLE_REDIRECT_PATH = process.env.REACT_APP_FFM_CONSOLE_REDIRECT_PATH || '/login';
export const FFM_CONSOLE_NEXT_REDIRECT_PATH = process.env.REACT_APP_FFM_CONSOLE_NEXT_REDIRECT_PATH || '/api/home';

export const MIXPANEL_ENABLED = process.env.REACT_APP_MIXPANEL_ENABLED || 'true';
export const MIXPANEL_KEY = process.env.REACT_APP_MIXPANEL_KEY || 'fbd167f71f44314e522d3ad06ba94fcb';

// TuneCore dev user referrer id
export const BUDGET_PACKAGES_REFERRER_IDS =  process.env.BUDGET_PACKAGES_REFERRER_IDS ? process.env.BUDGET_PACKAGES_REFERRER_IDS.split(/\s+/) : [ '5511b6a01035299b24e6684a' ];

export const INTERCOMIO_APP_ID = process.env.REACT_APP_INTERCOMIO_APP_ID || 'e5x7urok';
