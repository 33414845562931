import ExtendedAxios from "../helpers/axios";

import { FFM_CONSOLE_API_URL } from "../config/env";

const axios = new ExtendedAxios({ baseURL: FFM_CONSOLE_API_URL });

export default class AwsService {
  async getSignatureForFile(input, bucketType) {

    const file = {
      type: input.type,
      name: input.name,
      size: input.size
    };

    return await axios.post( "/aws-signature-single-file?bucketType=" + bucketType, { file } );
  }

  uploadFile({
    url,
    signature,
    policy,
    file,
    onProgress
  }) {
    return new Promise( ( resolve, reject ) => {
      const xhr = new XMLHttpRequest();
      xhr.open("PUT", signature);
      //xhr.setRequestHeader('x-amz-acl', 'public-read');

      xhr.upload.onprogress = evt => {
        if (evt.lengthComputable) {
          const percentComplete = (evt.loaded / evt.total) * 100;
          if (onProgress) {
            onProgress(percentComplete);
          }
        }
      };

      xhr.onreadystatechange = () => {
        if (xhr.readyState == 4 && xhr.status == 200) {
          resolve( xhr.response );
        }
      };

      xhr.onerror = error => {
        reject( error );
      };

      xhr.send( file );
    } )
  }
}
