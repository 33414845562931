import { store } from './store';
import { checkAuthorization } from './auth/actions';
import { getInvitationData } from './acceptInvitation/actions';
import { useAffiliate } from './affiliate/actions';

import * as ENV from '@featurefm/common/config/env';

export default () =>
  new Promise((resolve) => {
    // console.info("Store is booting, checking for Auth");
    // console.info("Environment:", ENV );

    store.dispatch(useAffiliate());
    store.dispatch(getInvitationData());
    store.dispatch(checkAuthorization());
    resolve()
  });
