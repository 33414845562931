import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Redirect, Switch } from "react-router-dom";
import { ScaleLoader } from "react-spinners";

//  New Pages
//  We want to bundle some and
//  lazy load others :)
import { ReverseRestrictedRoute, RestrictedRoute, NonRestrictedRoute } from './common/RouteComponents';
import { Login, LoginAcceptInvite, Signup, SignupAcceptInvite, AcceptTerms, Forgot, SendInviteLoading, LoadingScreen, EmailConfirmationLoading } from "../containers";

const OnboardingArtist = React.lazy(() => import("../containers/Onboarding/OnboardingArtist"));
const OnboardingLabel = React.lazy(() => import("../containers/Onboarding/OnboardingLabel"));

class Router extends React.Component {
  render() {
    const { user, authorized, appLoading, authLoading, userError, invitationHash } = this.props;
    const isAuthenticated = !userError && ( user != null || authorized );

    const restrictedRouteOptions = { appLoading, authLoading, isAuthenticated, invitationHash };

    return (<>
      { appLoading && <LoadingScreen /> }
      { !appLoading &&
        <Switch>
          {/* Root of this project is Login for now */}
          <ReverseRestrictedRoute {...restrictedRouteOptions} exact path="/login" component={Login} />
          <ReverseRestrictedRoute {...restrictedRouteOptions} exact path="/manage-delegate-from-invitation/login" component={LoginAcceptInvite} />
          <ReverseRestrictedRoute {...restrictedRouteOptions} exact path="/signup" component={Signup} />
          <ReverseRestrictedRoute {...restrictedRouteOptions} exact path="/manage-delegate-from-invitation/signup" component={SignupAcceptInvite} />
          <ReverseRestrictedRoute {...restrictedRouteOptions} exact path="/forgot" component={Forgot} />

          <ReverseRestrictedRoute {...restrictedRouteOptions} exact path="/accept-terms" component={AcceptTerms} />

          <NonRestrictedRoute {...restrictedRouteOptions} exact path="/send-invite" component={SendInviteLoading} />

          <NonRestrictedRoute {...restrictedRouteOptions} exact path="/email-address-confirmation" component={EmailConfirmationLoading} />

          {/* Onboarding route is Lazy loaded */}
          <RestrictedRoute
            {...restrictedRouteOptions}
            exact
            path="/artists-onboarding"
          >
            <Suspense fallback={ScaleLoader}>
              <OnboardingArtist />
            </Suspense>
          </RestrictedRoute>
          <RestrictedRoute
            {...restrictedRouteOptions}
            exact
            path="/labels-onboarding"
          >
            <Suspense fallback={ScaleLoader}>
              <OnboardingLabel />
            </Suspense>
          </RestrictedRoute>

          <Redirect from="*" to="/login" />
        </Switch>
      }
    </>);
  }
}

export default connect((state) => ({
  userError: state.Auth.userError,
  user: state.Auth.user,
  authorized: state.Auth.authorized,
  appLoading: state.App.loading,
  authLoading: state.Auth.loading,
  invitationHash: state.AcceptInvitation.originalHash,
}))(Router);
