import { INTERCOMIO_APP_ID } from '@featurefm/common/config/env';

export const LEGACY_THEME_ROUTES = [
  '/artists-onboarding',
  '/labels-onboarding'
]

export const OVERSCROLL_BG_ROUTES = {
  '/login': 'bgDefault',
  '/signup': 'bgDefault',
  '/forgot': 'bgDefault',
  '/login/': 'bgDefault',
  '/signup/': 'bgDefault',
  '/forgot/': 'bgDefault',
}

export const INTERCOM_CONFIG = {
  app_id: INTERCOMIO_APP_ID,
}
