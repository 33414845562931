import React from 'react'
import { Parallax, Background } from 'react-parallax';
import { ScaleLoader } from 'react-spinners';

import {
  Wrapper,
  ErrorWrapper
} from '@featurefm/common/containers/Error/error.style';
//  Images
import dotsBg from '@featurefm/common/assets/images/dots-background.png';

export const PARALLAX_GLOBAL_STRENGTH = 850;

export default function LoadingScreen() {
  return (
    <Wrapper>
      <Parallax strength={PARALLAX_GLOBAL_STRENGTH}>
        <Background>
          <img src={dotsBg} alt="Dots Parallax background" />
        </Background>

        <ErrorWrapper>
          <ScaleLoader color={'#fff'} />
        </ErrorWrapper>
      </Parallax>
    </Wrapper>
  )
}
