import ExtendedAxios from '../helpers/axios';

import { FFM_CONSOLE_API_URL } from '../config/env';

const axios = new ExtendedAxios({baseURL: FFM_CONSOLE_API_URL});

export default class AuthService {
  constructor( store ) {
    if ( store ) {
      axios.store = store;
    }
  }

  static login( params ) {
    return axios.post( '/login', params )
  }

  static impersonate( params ) {
    return axios.post( '/impersonate', params )
  }

  static unimpersonate( params ) {
    return axios.post( '/unimpersonate', params )
  }

  static logout( params ) {
    return axios.get( '/logout' )
  }

  static register( params ) {
    return axios.post( '/register', params )
  }

  static resetPassword( username ) {
    return axios.post( '/reset-password-request', { username } )
  }

  static auth() {
    return axios.get( '/auth' )
  }

  static user() {
    return axios.get( '/user' )
  }
}
