import {
  ERROR_TYPES,
} from "@featurefm/common/helpers/invitation";

import {
  all,
  takeEvery,
  put,
  fork,
  select, call
} from "redux-saga/effects";

import actions from "./actions";
import InvitationService from "@featurefm/common/services/invitation";
/**
 * Returns react-router location
 *
 * @param {object} state - redux state
 */
export const getLocation = state => {
  return state.router.location;
};

export function* sendInvitation() {
  yield takeEvery(actions.SEND_INVITATION, function*({ jwt }) {

    const isCurrentlySending = yield select((state) => state.SendInvitation.isSending);
    // found invitation code but already in accepting process, skipping
    if (isCurrentlySending) return;

    yield put({
      type: actions.SET_SENDING_INVITATION,
    });

    try {
      const dest = yield select((state) => state.App.dest);
      const sendInvitationResponse = yield call(InvitationService.send, jwt, dest);
      if (!sendInvitationResponse || sendInvitationResponse.error) {
        const errorData = ((sendInvitationResponse || {}).error || {});

        const error = new Error(errorData.message || 'internal error');
        error.code = errorData.status || 500;
        throw error;
      }

      yield put({
        type: actions.SET_SENT_INVITATION,
        payload: true,
      });
    }
    catch(err) {
      yield put({
        type: actions.SET_SEND_INVITATION_ERROR,
        error: ERROR_TYPES.GENERIC_ACCEPT_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(sendInvitation),
  ]);
}
