import React from 'react'
import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { LocationDropdownLabel } from '../styled';

const Flag = styled.div`
  width: 16px;
  height: 16px;

  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;

    object-fit: contain;
  }
`;

export default function LocationLabel({id,location}) {
  const flag = require(`svg-country-flags/svg/${ location.code.slice(0,2) }.svg`);

  return (
    <LocationDropdownLabel id={ id || `location-option-${ location.code }`}>
      <Flag>
        <LazyLoadImage src={ flag } alt={location.text}/>
      </Flag>
      <span>{ location.text }</span>
    </LocationDropdownLabel>
  )
}
